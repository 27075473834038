<template>
    <div class="top-view-wrap">
        <div class="banner-content"></div>
        <div class="top-view">
            <h1>{{$t("footer.recoverPassword")}}</h1>
            <router-link to="/" tag="a">{{$t("login.startLocating")}}</router-link>
            <div class="form-view">
                <div class="input-box">
                    <p>{{$t("login.e_amil")}}</p>
                    <input type="text" class="email" v-model="email" @keydown="emailKeydown($event)">
                </div>
                <a class="enter repeat-btn" id="recoverBtn" @click="recover">{{$t("login.rpUP")}}</a>

                <p class="text-pc">
                    <span>{{$t("login.rpDesc")}}</span>
                    <router-link to="/login" tag="a"> {{$t("login.login")}}</router-link>
                </p>
                <p class="text-m">
                    <span>{{$t("login.rpDesc")}}</span>
                    <router-link to="/login" tag="a"> {{$t("login.login")}}</router-link>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "recover-password",
        data(){
            return {
                email: ''
            }
        },
        created() {
            // this.amplitude.getInstance().logEvent('RecoverPasswordScreen')
            this.eventLog('RecoverPasswordScreen')
        },
        methods:{
            emailKeydown(e){
                if (e.key === 'Enter') {
                    this.recover()
                }
            },
            recover() {
                let email = this.email.trim()
                if (!this.isEmailAvailable(email)) {
                    this.toast(this.$t("tips.enterVaildEmail"))
                    return
                }
                this.eventLog('recover-password')
                this.loading()
                this.$axios.put(`/admin/userSession/restPassword/${email}`).then(res=>{
                    this.hideLoading()
                    if (res.success == '1') {
                        this.toast(this.$t("tips.rePasswordSuccess"))
                    }else {
                        this.toast(res.msg)
                    }
                }).catch(err=>{
                    this.hideLoading()
                    this.toast(err.msg)
                })
            }
        }
    }
</script>

<style scoped>
    @media (min-width:960px) {
        .top-view-wrap {
            width: 100%;
            height: 870px;
            overflow-x: hidden;
            position: relative;
        }
        .top-view-wrap .banner-content {
            width: 100%;
            height: 359px;
        }
        .top-view-wrap .banner-content::before {
            content: " ";
            width: 150%;
            height: 359px;
            position: absolute;
            top: 0;
            left: -25%;
            border-bottom-left-radius: 50%;
            border-bottom-right-radius: 50%;
            background: #3373B6;
            z-index: -2;
        }
        /* .top-view-wrap .banner-content::after {
            content: " ";
            width: 1137px;
            height: 359px;
            position: absolute;
            top: 0;
            right: 321px;
            background: url('/@/assets/images/header-banner-Texture.png');
            z-index: -1;
        } */
        .top-view {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
        .top-view h1 {
            font-size: 60px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 71px;
            text-align: center;
            margin-top: 64px;
        }
        .top-view>a {
            width: 248px;
            height: 48px;
            background: linear-gradient(180deg, #FE836D 0%, #FE6698 100%);
            box-shadow: 0px 6px 20px 0px rgba(47, 123, 230, 0.5);
            border-radius: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            font-weight: 400;
            color: #FFFFFF;
            margin: 24px auto 0;
        }
        .top-view>a:hover {
            background: linear-gradient(180deg, #E47461 0%, #E45B88 100%);
        }
        .form-view {
            width: 480px;
            background: #FFFFFF;
            box-shadow: 0px 18px 30px 0px rgba(45, 91, 140, 0.14);
            border-radius: 12px;
            padding: 40px;
            box-sizing: border-box;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 295px;
        }
        .form-view .input-box {
            width: 100%;
            margin-bottom: 32px;
        }
        .form-view .input-box p {
            text-align: left;
            font-size: 16px;
            font-weight: 400;
            color: #708198;
            line-height: 19px;
            margin-bottom: 10px;
        }
        .form-view .input-box input {
            width: 100%;
            height: 42px;
            background: #EDEFF2;
            border-radius: 4px;
            border: none;
            padding: 0 16px;
            box-sizing: border-box;
            font-size: 16px;
            font-weight: 400;
            color: #444444;
            transition: all .3s;
        }
        .form-view .input-box input:focus {
            box-shadow: 0px 2px .16rem 0px rgba(45, 91, 140, 0.14);
            background: #FFFFFF;
        }
        .form-view .enter {
            display: block;
            width: 248px;
            height: 60px;
            background: #33B592;
            border-radius: 30px;
            font-size: 18px;
            font-weight: 500;
            color: #FFFFFF;
            text-align: center;
            line-height: 60px;
            margin: 0 auto;
            cursor: pointer;
        }
        .form-view .enter:hover {
            background: #289074;
        }
        .form-view .text-pc {
            font-size: 16px;
            font-weight: 400;
            color: #999999;
            line-height: 19px;
            margin-top: 24px;
            text-align: center;
        }
        .form-view .text-m {
            display: none;
        }
        .form-view .text-pc a {
            color: #4083FC;
        }
        .footer-box {
            margin-top: 10%;
        }
    }

    @media (max-width: 960px) {
        .top-view-wrap {
            width: 100%;
            height: 10.5rem;
            overflow-x: hidden;
        }
        .top-view {
            width: 100%;
            background: #3373B6;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 1.2rem 0 2rem;
            position: relative;
        }
        .top-view::after {
            content: ' ';
            display: block;
            width: 7.68rem;
            height: 1.26rem;
            background: url('/@/assets/images/index-one-bg.svg');
            background-size: 7.68rem 1.26rem;
            position: absolute;
            left: -.07rem;
            bottom: -1px;
        }
        .top-view h1 {
            font-size: .64rem;
            font-weight: 600;
            color: #FFFFFF;
            line-height: .75rem;
        }
        .top-view>a {
            width: 3.2rem;
            height: .72rem;
            background: linear-gradient(180deg, #FE836D 0%, #FE6698 100%);
            box-shadow: 0px .06rem .2rem 0px rgba(47, 123, 230, 0.5);
            border-radius: .44rem;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: .26rem;
            font-weight: 400;
            color: #FFFFFF;
            margin-top: .24rem;
        }
        .top-view>a:active {
            background: linear-gradient(180deg, #E47461 0%, #E45B88 100%);
        }
        .form-view {
            width: 6.86rem;
            height: 5rem;
            background: #FFFFFF;
            box-shadow: 0px .18rem .3rem 0px rgba(45, 91, 140, 0.14);
            border-radius: .12rem;
            padding: .8rem .4rem;
            box-sizing: border-box;
            position: absolute;
            left: .32rem;
            top: 3.2rem;
            overflow-y: auto;
            overflow-x: hidden;
            z-index: 100;
        }
        .form-view .input-box {
            width: 100%;
            margin-bottom: .6rem;
        }
        .form-view .input-box p {
            font-size: .26rem;
            font-weight: 400;
            color: #708198;
            line-height: .3rem;
            margin-bottom: .12rem;
            text-align: left;
        }
        .form-view .input-box input {
            width: 100%;
            height: .72rem;
            background: #EDEFF2;
            border-radius: .12rem;
            border: none;
            padding: 0 .32rem;
            box-sizing: border-box;
            font-size: .28rem;
            font-weight: 400;
            color: #444444;
            transition: all .3s;
        }
        .form-view .input-box input:focus {
            box-shadow: 0px 2px .16rem 0px rgba(45, 91, 140, 0.14);
            background: #FFFFFF;
        }
        .form-view .enter {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 4.8rem;
            height: .88rem;
            background: #33B592;
            border-radius: .44rem;
            font-size: .32rem;
            font-weight: 500;
            color: #FFFFFF;
            line-height: .88rem;
            margin: 0 auto;
            cursor: pointer;
        }
        .form-view .enter:active {
            background: #289074;
        }
        .form-view .text-pc {
            display: none;
        }
        .form-view .text-m {
            font-size: .28rem;
            font-weight: 400;
            color: #999999;
            line-height: .33rem;
            text-align: center;
            margin-top: .4rem;
        }
        .form-view .text-m a {
            color: #4083FC;
        }
        .footer-box {
            margin-top: 75%;
        }
    }
</style>
