<template>
    <div class="login-box">
        <div class="banner-content"></div>
        <div class="top">
            <h1>Contact us</h1>
        </div>
        <div class="contact">
            <ul>
                <li>
                    <img src="@/assets/images/contact-icon-email.png" alt="">
                    <span><a href="mailto:Findmyphone@chamspy.com">Findmyphone@chamspy.com</a></span>
                </li>
                <li>
                    <img src="@/assets/images/contact-icon-time.png" alt="">
                    <span>{{$t("contact.time")}}</span>
                </li>

                <li>
                    <img src="@/assets/images/contact-icon-phone-number.png" alt="">
                    <span>+1 310-912-3680</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: "contact",
        created() {
            // this.amplitude.getInstance().logEvent('ContactUsScreen')
            this.eventLog('ContactUsScreen')
        }
    }
</script>

<style lang="scss" scoped>
    @import "../style/login";
    
    @media (max-width:960px){
        .login-box .top{
            height: 2.25rem;
            overflow: hidden;
        }
       .login-box .top::before{
            content: " ";
            width: 126%;
            height: 2.25rem;
            left: -13%;
            position: absolute;
            background: #3373B6;
            border-radius: 0% 0% 50% 50%;
        }
    }
</style>
