<template>
    <div class="rl-wrap">
        <div id="localisation" v-html="localisation_text"></div>
        <div id="map"></div>
    </div>
</template>

<script>
    import {Loader} from 'google-maps'
    export default {
        name: "reveal-location",
        data(){
            return{
                google:null,
                map: null,
                localisation_text: ''
            }
        },
        async mounted() {
            let lat = parseFloat(this.$getQueryString('lat'));
            let lng = parseFloat(this.$getQueryString('lng'));
            let prec = parseFloat('30');
            let numGeoloc = this.$getQueryString('phone');
            // let dateGeo = this.$getQueryString('date');
            let latlng = {
                lat: lat,
                lng: lng
            };

            const loader = new Loader('AIzaSyAEfCN_QFZ2-EDsXeyuesxozpLaL73mYT4')
            this.google = await loader.load()
            let geocoder = new this.google.maps.Geocoder();
            geocoder.geocode({
                'latLng': latlng
            }, (results, status) => {
                if (status === this.google.maps.GeocoderStatus.OK) {
                    if (results[0]) {
                        this.localisation_text = "<br/><h2>" + this.$t("revealLocation.startLocating")  + numGeoloc + " : " + results[0].formatted_address + "</h2><br/>";
                    } else {
                        this.localisation_text = "<br/><h2>" + this.$t("revealLocation.errText") + "</h2>";
                    }
                } else {
                    this.localisation_text = "<br/><h2>" + this.$t("revealLocation.errText") + "</h2>";
                }
            });

            let centerpos = new this.google.maps.LatLng(48.862388, 2.347551);
            let optionsGmaps = {
                center: centerpos,
                navigationControlOptions: {
                    style: this.google.maps.NavigationControlStyle.SMALL
                },
                mapTypeId: this.google.maps.MapTypeId.ROADMAP,
                zoom: 8
            };
            this.map = new this.google.maps.Map(document.getElementById('map'), optionsGmaps)
            new this.google.maps.Marker({
                position: latlng,
                map: this.map,
                title: this.$t("revealLocation.marker"),
                opacity: 0.8
            });
            new this.google.maps.Circle({
                strokeColor: '#FF0000',
                strokeOpacity: 0.5,
                strokeWeight: 2,
                fillColor: '#FF0000',
                fillOpacity: 0.1,
                map: this.map,
                center: latlng,
                radius: prec * 2
            });
            this.map.panTo(latlng);
        }

    }
</script>

<style lang="scss" scoped>
    h2 {
        font-weight: 500;
        color: #317eac;
        text-align: center;
        font-size: .26rem;
    }

    #map {
        width: 100%;
        height: 80vh;
    }

    .rl-wrap {
        width: 100%;
        padding: .32rem;
        box-sizing: border-box;
    }
</style>
