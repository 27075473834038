<template>
    <div class="top-view-wrap">
        <div class="banner-content"></div>
        <div class="top-view">
            <h1>{{$t("account.title")}}</h1>
            <a @click="goRouter('location-phone')">{{$t("account.titleBtn")}}</a>
            <div class="form-view">
                <div class="form-nav">
                    <div :class="tabIndex==0? 'active':''" @click="tabClick(0,$event)">
                        <span>{{$t("account.accountUP")}}</span>
                    </div>
                    <div :class="tabIndex==1? 'active':''" @click="tabClick(1,$event)">
                        <span>{{$t("account.historyUP")}}</span>
                    </div>
                    <span class="line" ref="line"></span>
                </div>
                <div class="form-content" :style="{transform: `translateX(${translateX}px)`}" ref="tabBox">
                    <div class="account-box tab-box">
                        <h4>{{$t("account.accountTitle")}}</h4>
                        <p>E-MAIL</p>
                        <div class="email">
                            <span>{{email}}</span>
                        </div>
                        <p>{{$t("login.passwordUP")}}</p>
                        <input class="password" type="password" v-model="password" @keydown="passwordKeydown($event)">
                        <a class="save" @click="save">{{$t("account.accountBtn")}}</a>
                        <!-- <a @click="goRouter('cancel-order')" class="a-txt">UNSUBSCRIBE</a> -->
                    </div>
                    <div class="history-box tab-box">
                        <h4>{{$t("account.historyOneTitle")}}</h4>
                        <div class="table payment">
                            <div class="no-data" v-if="payment_list.length === 0">{{$t("account.noData")}}</div>
                            <div class="table-title" v-if="payment_list.length !== 0">
                                <span style="width: 70%;">{{$t("account.date")}}</span>
                                <span style="width: 30%;">{{$t("account.payment")}}</span>
                            </div>
                            <template v-if="payment_list.length !== 0">
                                <div class="table-title" v-for="(item,index) in payment_list" :key="index">
                                    <span style="width: 70%;">{{item.payTime | timeFormat("YYYY-mm-dd")}}</span>
                                    <span style="width: 30%;">{{item.money}}</span>
                                </div>
                            </template>
                        </div>
                        <h4>{{$t("account.historyTwoTitle")}}</h4>
                        <div class="table shipping">
                            <div class="no-data" v-if="shipping_list.length === 0">{{$t("account.noData")}}</div>
                            <div class="table-title" v-if="shipping_list.length !== 0">
                                <span style="width: 27%;">{{$t("account.date")}}</span>
                                <span style="width: 47%;">{{$t("account.phoneNumber")}}</span>
                                <span style="width: 26%;">{{$t("account.state")}}</span>
                            </div>
                            <template v-if="shipping_list.length !== 0">
                                <div class="table-title" v-for="(item,index) in shipping_list" :key="index">
                                    <span style="width: 27%;">{{item.json.time | timeFormat("YYYY-mm-dd")}}</span>
                                    <span style="width: 47%;">{{item.json.childPhone}}</span>
                                    <span style="width: 26%;">{{item.json.sendTime != null ? 'Approved' : 'Pending'}}</span>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    export default {
        name: "account",
        data(){
            return{
                password: '',
                payment_list: [],
                shipping_list: [],
                tabIndex: 0,
                translateX: 0
            }
        },
        computed: {
            email() {
              return this.userInfo ? this.userInfo.email : ''
            },
            ...mapGetters(['userInfo'])
        },
        async created() {
            try {
                this.loading()
                let date = new Date();
                date.setTime(date.getTime() - 6 * 24 * 60 * 60 * 1000)
                let createDate = this.timeFormat(date, 'YYYYmmdd')
                let spyContent = await this.$axios.get('/spyContent',{params:{
                        createDateBegin: createDate,
                        dataUniqueIdLike: '+',
                        sortColumns: 'id desc'
                    }})
                let orders = await this.$axios.get('/admin/order',{params:{statuses: '1,2'}})

                if (spyContent.success != '1') {
                    this.toast(spyContent.msg)
                    return
                }
                if (orders.success != '1') {
                    this.toast(orders.msg)
                    return
                }
                this.shipping_list = this.handleData(spyContent.page).result
                this.payment_list = orders.page.result
                this.hideLoading()
            } catch (e) {
                console.log(e)
                this.hideLoading()
                this.toast(e.msg)
            }
        },
        methods:{
            tabClick(index,event){
                this.tabIndex = index
                this.$refs.line.style.left = event.target.offsetLeft + 'px'
                this.translateX = -(this.$refs.tabBox.clientWidth * index) / (index + 1)
            },
            passwordKeydown(e) {
                if (e.key === 'Enter') {
                    this.save()
                }
            },
            save() {
                let password = this.password.trim()
                if (password.indexOf(" ") !== -1) {
                    this.toast(this.$t("tips.passWordNoSpaces"))
                    return
                }
                if (password.length < 6) {
                    this.toast(this.$t("tips.passWordNoSix"))
                    return
                }

                this.loading()
                this.$axios.put('/admin/user/password',{
                    newPassword: password
                }).then(res=>{
                    this.hideLoading()
                    if (res.success != '1') {
                        this.toast(res.msg)
                        return
                    }
                    this.toast(this.$t("tips.successModified"))
                }).catch(err=>{
                    this.hideLoading()
                    this.toast(err.msg)
                })
            },
            goRouter(path) {
                if (path === 'cancel-order') {
                    // this.amplitude.getInstance().logEvent('UnSubscribeScreen',{
                    //     Source: 'Account'
                    // })
                    this.eventLog('UnSubscribeScreen-Account')
                }
                this.$router.push({
                    path
                })
            }
        }
    }
</script>

<style lang="scss" scoped>

    @media (min-width:960px) {
        .top-view-wrap {
            width: 100%;
            height: 870px;
            overflow-x: hidden;
            position: relative;
        }
        .top-view-wrap .banner-content {
            width: 100%;
            height: 359px;
        }
        .top-view-wrap .banner-content::before {
            content: " ";
            width: 150%;
            height: 359px;
            position: absolute;
            top: 0;
            left: -25%;
            border-bottom-left-radius: 50%;
            border-bottom-right-radius: 50%;
            // background: linear-gradient(to right, #5793FF, #75CFFF);
            background: #3373B6;
            z-index: -2;
        }
        // .top-view-wrap .banner-content::after {
        //     content: " ";
        //     width: 1137px;
        //     height: 359px;
        //     position: absolute;
        //     top: 0;
        //     right: 321px;
        //     background: url('/@/assets/images/header-banner-Texture.png');
        //     z-index: -1;
        // }
        .top-view {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
        .top-view h1 {
            font-size: 60px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 71px;
            text-align: center;
            margin-top: 64px;
        }
        .top-view>a {
            width: 248px;
            height: 48px;
            background: linear-gradient(180deg, #FE836D 0%, #FE6698 100%);
            box-shadow: 0px 6px 20px 0px rgba(47, 123, 230, 0.5);
            border-radius: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            font-weight: 400;
            color: #FFFFFF;
            margin: 24px auto 0;
        }
        .top-view>a:hover {
            background: linear-gradient(180deg, #E47461 0%, #E45B88 100%);
        }
        .form-view {
            width: 480px;
            height: 538px;
            background: #FFFFFF;
            box-shadow: 0px 18px 30px 0px rgba(45, 91, 140, 0.14);
            border-radius: 12px;
            padding: 0 0 40px;
            box-sizing: border-box;
            margin: 84px auto 0;
            overflow-x: hidden;
            position: relative;
        }
        .form-content {
            display: flex;
            width: calc(480px * 2);
            transition: all .3s;
        }
        .account-box,
        .history-box {
            width: 480px;
            height: 408px;
            padding: 39px 40px 0;
            overflow-y: auto;
            box-sizing: border-box;
            text-align: left;
        }
        .form-view .form-nav {
            width: calc(100% - 80px);
            display: flex;
            /* margin-bottom: 39px; */
            position: relative;
            padding-top: 40px;
            position: sticky;
            left: 0;
            top: 0;
            background: #ffffff;
            box-sizing: border-box;
            margin: 0 auto;
        }
        .form-view .form-nav div {
            flex: 1;
            display: flex;
            flex-direction: column;
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            color: #708198;
            cursor: pointer;
            overflow: hidden;
        }
        .form-view .form-nav div:hover {
            color: #5793FF;
        }
        .form-view .form-nav div span {
            padding-bottom: 24px;
            border-bottom: 2px solid #EDEFF2;
        }
        .form-view .form-nav .line {
            display: block;
            width: calc(100% / 2);
            height: 2px;
            background: #5793FF;
            transition: all .3s;
            position: absolute;
            bottom: 0;
            left: 0;
        }
        .form-view .form-nav div.active {
            color: #5793FF;
        }
        .form-view .account-box h4 {
            font-size: 20px;
            color: #333333;
            margin-bottom: 34px;
        }
        .form-view .account-box p {
            font-size: 16px;
            font-weight: 400;
            color: #708198;
            line-height: 19px;
            margin-bottom: 10px;
        }
        .form-view .account-box .email {
            width: 100%;
            height: 42px;
            padding: 0 30px;
            line-height: 42px;
            background: #F6F7F8;
            border-radius: 4px;
            font-size: 16px;
            font-weight: 400;
            color: #444444;
            line-height: 42px;
            margin-bottom: 20px;
            box-sizing: border-box;
        }
        .form-view .account-box input {
            width: 100%;
            height: 42px;
            padding: 0 30px;
            background: #EDEFF2;
            box-sizing: border-box;
            border: none;
            font-size: 16px;
            border-radius: 4px;
        }
        .form-view .account-box input:focus {
            box-shadow: 0px 2px 16px 0px rgba(45, 91, 140, 0.14);
            background: #FFFFFF;
        }
        .form-view .account-box .save {
            display: block;
            width: 248px;
            height: 60px;
            background: #33B592;
            border-radius: 30px;
            margin: 32px auto;
            font-size: 18px;
            font-weight: 500;
            color: #FFFFFF;
            text-align: center;
            line-height: 60px;
            cursor: pointer;
        }
        .form-view .account-box .save:hover {
            background: #289074;
        }
        .form-view .account-box .a-txt {
            display: block;
            width: 115px;
            height: 19px;
            font-size: 16px;
            font-weight: 400;
            color: #999999;
            line-height: 19px;
            margin: 0 auto;
        }
        .form-view .account-box .a-txt:hover {
            color: #898989;
        }
        .form-view .history-box h4 {
            font-size: 20px;
            color: #333333;
            line-height: 24px;
            margin-bottom: 20px;
        }
        .form-view .history-box .table {
            width: 100%;
        }
        .form-view .history-box .table:nth-child(2) {
            margin-bottom: 40px;
        }
        .form-view .history-box .table>div {
            display: flex;
        }
        .form-view .history-box .table>div span {
            /* flex: 1; */
            font-size: 16px;
            font-weight: 400;
            color: #708198;
            line-height: 19px;
            padding: 10px;
            box-sizing: border-box;
        }
        .form-view .history-box .table>div span:last-child {
            text-align: right;
        }
        .form-view .history-box .table .table-title {
            border-bottom: 1px solid #EDEFF2;
            margin-bottom: 5px;
        }
        .form-view .history-box .table .no-data {
            font-size: 16px;
            font-weight: 400;
            color: #708198;
            line-height: 19px;
        }
        .footer-box {
            margin-top: 10%;
        }
    }

    @media (max-width: 960px) {
        .top-view-wrap {
            width: 100%;
            height: 13rem;
            overflow-x: hidden;
        }
        .top-view {
            width: 100%;
            background: #3373B6;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 1.2rem 0 2rem;
            position: relative;
        }
        .top-view::after {
            content: ' ';
            display: block;
            width: 7.68rem;
            height: 1.26rem;
            background: url('/@/assets/images/index-one-bg.svg');
            background-size: 7.68rem 1.26rem;
            position: absolute;
            left: -.07rem;
            bottom: -1px;
        }
        .top-view h1 {
            font-size: .64rem;
            font-weight: 600;
            color: #FFFFFF;
            line-height: .75rem;
        }
        .top-view>a {
            // width: 3.2rem;
            padding: 0 .4rem;
            height: .72rem;
            background: linear-gradient(180deg, #FE836D 0%, #FE6698 100%);
            box-shadow: 0px .06rem .2rem 0px rgba(47, 123, 230, 0.5);
            border-radius: .44rem;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: .26rem;
            font-weight: 400;
            color: #FFFFFF;
            margin-top: .24rem;
        }
        .top-view>a:active {
            background: linear-gradient(180deg, #E47461 0%, #E45B88 100%);
        }
        .form-view {
            width: 6.86rem;
            height: 7.8rem;
            background: #FFFFFF;
            box-shadow: 0px .18rem .3rem 0px rgba(45, 91, 140, 0.14);
            border-radius: .12rem;
            padding: 0rem 0rem .6rem;
            box-sizing: border-box;
            position: absolute;
            left: .32rem;
            top: 3.2rem;
            overflow-y: auto;
            overflow-x: hidden;
            z-index: 100;
        }
        .form-content {
            display: flex;
            width: 13.72rem;
            transition: all .3s;
        }
        .account-box,
        .history-box {
            flex: 1;
            height: 5.88rem;
            padding: .4rem .4rem 0;
            overflow-y: auto;
            box-sizing: border-box;
            text-align: left;
        }
        .form-view .form-nav {
            width: calc(100% - .4rem);
            display: flex;
            margin-bottom: .4rem;
            position: relative;
            padding-top: .6rem;
            position: sticky;
            left: 0;
            top: 0;
            background: #ffffff;
            margin: 0 auto;
            z-index: 1;
        }
        .form-view .form-nav div {
            flex: 1;
            display: flex;
            flex-direction: column;
            text-align: center;
            font-size: .26rem;
            font-weight: 400;
            color: #708198;
            cursor: pointer;
            overflow: hidden;
        }
        .form-view .form-nav div.active {
            color: #5793FF;
        }
        .form-view .form-nav div:active {
            color: #5793FF;
        }
        .form-view .form-nav div span {
            padding-bottom: .3rem;
            border-bottom: 2px solid #EDEFF2;
        }
        .form-view .form-nav .line {
            display: block;
            width: calc(100% / 2);
            height: 2px;
            background: #5793FF;
            transition: all .3s;
            position: absolute;
            bottom: 0;
            left: 0;
        }
        .form-view .account-box h4 {
            font-size: .32rem;
            color: #333333;
            line-height: .38rem;
            margin-bottom: .4rem;
        }
        .form-view .account-box p {
            font-size: .26rem;
            font-weight: 400;
            color: #708198;
            line-height: .30rem;
            margin-bottom: .12rem;
        }
        .form-view .account-box .email {
            width: 100%;
            height: .72rem;
            padding: 0 .3rem;
            line-height: .72rem;
            background: #F6F7F8;
            border-radius: .12rem;
            font-size: .28rem;
            font-weight: 400;
            color: #444444;
            box-sizing: border-box;
            margin-bottom: .4rem;
        }
        .form-view .account-box input {
            width: 100%;
            height: .72rem;
            padding: 0 .3rem;
            background: #EDEFF2;
            box-sizing: border-box;
            border: none;
            font-size: .28rem;
            border-radius: .12rem;
        }
        .form-view .account-box input:focus {
            box-shadow: 0px 2px .16rem 0px rgba(45, 91, 140, 0.14);
            background: #FFFFFF;
        }
        .form-view .account-box .save {
            display: block;
            width: 4.80rem;
            height: .88rem;
            background: #33B592;
            border-radius: .44rem;
            font-size: .32rem;
            font-weight: 500;
            color: #FFFFFF;
            line-height: .88rem;
            text-align: center;
            margin: .4rem auto;
            cursor: pointer;
        }
        .form-view .account-box .save:active {
            background: #289074;
        }
        .form-view .account-box .a-txt {
            width: 2.4rem;
            display: block;
            margin: 0 auto;
            font-size: .28rem;
            font-weight: 400;
            color: #999999;
            line-height: .33rem;
            text-align: center;
        }
        .form-view .account-box .a-txt:active {
            color: #898989;
        }
        .form-view .history-box {
            width: 100%;
        }
        .form-view .history-box h4 {
            font-size: .32rem;
            color: #333333;
            line-height: .38rem;
            margin-bottom: .3rem;
        }
        .form-view .history-box .table {
            width: 100%;
        }
        .form-view .history-box .table:nth-child(2) {
            margin-bottom: .6rem;
        }
        .form-view .history-box .table>div {
            display: flex;
        }
        .form-view .history-box .table>div span {
            flex: 1;
            font-size: .26rem;
            font-weight: 400;
            color: #708198;
            line-height: .3rem;
            padding: .15rem .06rem;
        }
        .form-view .history-box .table>div span:last-child {
            text-align: right;
        }
        .form-view .history-box .table .table-title {
            border-bottom: 1px solid #EDEFF2;
            margin-bottom: .1rem;
        }
        .form-view .history-box .table .no-data {
            font-size: .26rem;
            font-weight: 400;
            color: #708198;
            line-height: .3rem;
        }
        .footer-box {
            margin-top: 75%;
        }
    }
</style>
