<template>
    <div class="lp-wrap" @click="sms_status = false">
        <div class="top-box-wrap">
            <div class="banner-content"></div>
            <div class="top-box">
                <div class="tips" :style="{display: tips_status? 'block' :'none'}">
                    <p>{{tips_text}}</p>
                    <div class="close-box" @click="tips_status = false">
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <h1>{{$t("index.title")}}</h1>
                <div class="form-box">
                    <div>
                        <p>{{$t("locationPhone.oneLabel")}}</p>
                        <div class="intl-tel-input allow-dropdown">
                            <input type="tel" id="phone" @focusout="phoneFocusout" @keydown="phoneKeydown($event)">
                        </div>
                    </div>
                    <div class="">
                        <p>{{$t("locationPhone.towLabel")}}</p>
                        <div class="intl-tel-input allow-dropdown">
                            <input type="tel" ref="ephone" id="ephone" @focusout="ephoneFocusout" @keydown="ephoneKeydown($event)">
                        </div>
                    </div>
                    <div>
                        <p>{{$t("locationPhone.threeLabel")}}</p>
                        <div class="sms-box" @click.stop.prevent="sms_status = !sms_status">
                            <p>{{smsList[smsIndex].value}}</p>
                            <span class="arrow"></span>
                            <div class="sms-list" v-show="sms_status">
                                <p v-for="(item,index) in smsList" @click="selectSms(index)" :key="index">{{item.value}}</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p>{{$t("locationPhone.fourLabel")}}</p>
                        <input class="personalized" type="tel" v-model="smsList[smsIndex].value" disabled>
                    </div>

                    <div class="btn" @click="find">{{$t("locationPhone.btn")}}</div>
                </div>
            </div>
        </div>

        <div class="text-box">
            <p class="title">{{$t("locationPhone.descTitle")}}</p>
            <p class="txt">{{$t("locationPhone.descContent")}}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "location-phone",
        data() {
            return{
                tips_text: '',
                tips_status: false,
                countryCode: localStorage.getItem('countryCode')|| 'US',
                smsList: [{
                    value: ''
                }],
                smsIndex: 0,
                sms_status: false,
                phone: '',
                ephone: ''
            }
        },
        created() {
            // this.amplitude.getInstance().logEvent('LocateThePhoneScreen')
            this.eventLog('LocateThePhoneScreen')
            this.loading()
            this.$axios.get('/commonCode',{
                params: {
                    preCode: 'phone_text'
                }
            }).then(res=>{
                this.hideLoading()
                if (res.success != '1'){
                    this.toast(res.msg)
                    return
                }
                this.smsList = res.result
            }).catch(err=>{
                this.hideLoading()
                this.toast(err.msg)
            })
        },
        mounted() {
            document.querySelector('.navbar').style.display = 'block'
            document.querySelector('.footer-box').style.display = 'block'
            let input = document.querySelector("#phone");
            let ephone = document.querySelector("#ephone");
            this.intlTelInput(this.countryCode,input)
            this.intlTelInput(this.countryCode,ephone)
        },
        methods:{
            find() {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                if (!userInfo) {
                    setTimeout(()=>{
                        this.$router.push({
                            path: '/login'
                        })
                    },2500)
                    this.toast(this.$t("tips.notLoginPleaseLogin"))
                    return
                }
                if (userInfo.del != 0) {
                    this.toast(this.$t("tips.reBuy"))
                    return
                }
                if (this.phone === '' || this.ephone === '') {
                    this.toast(this.$t("tips.phoneNumberNotBlank"))
                    return
                }
                if (this.phone === this.ephone) {
                    this.toast(this.$t("tips.phoneNumberDifferent"))
                    return
                }
                this.loading()
                window.firebase.analytics().logEvent('track_location')
                this.$axios.post('/spyContent/phoneLocation',{
                    parentPhone: this.ephone,
                    childPhone: this.phone,
                    deliveredCode: this.smsList[this.smsIndex].code,
                    sentSMS: true
                }).then(res=>{
                    if (res.success != '1') {
                        this.hideLoading()
                        this.toast(res.msg)
                        return
                    }
                    setTimeout(()=>{
                        this.$router.push({
                            path: '/map',
                            query:{
                                logEventSource: 'Account'
                            }
                        })
                    },2000)
                    localStorage.setItem('phoneNumber', this.phone)
                    sessionStorage.setItem('location_phone_id', res.obj.id)
                }).catch(err=>{
                    this.toast(err.msg)
                })
            },
            selectSms(index) {
                this.smsIndex = index
            },
            intlTelInput(countryCode,dom) {
                // let input = document.querySelector("#phone");
                window.intlTelInput(dom, {
                    initialCountry: countryCode,
                    preferredCountries: ['es'],
                    placeholderNumberType: "MOBILE",
                    formatOnDisplay: false,
                    utilsScript: "./js/intlTelInput-utils.js"
                });
            },
            phoneKeydown(e) {
                if (e.key == 'Enter') {
                    this.phone = this.validatePhoneNumber(window.$("#phone"))
                    if(this.phone !== '') {
                        this.$nextTick(()=>{
                            this.$refs.ephone.focus()
                        })
                    }
                    e.preventDefault();
                    return false;
                }
            },
            phoneFocusout() {
                this.phone = this.validatePhoneNumber(window.$("#phone"))
                if (this.phone !== '') {
                    this.$nextTick(()=>{
                        this.$refs.ephone.focus()
                    })
                }

            },
            ephoneKeydown(e) {
                if (e.key == 'Enter') {
                    this.ephone = this.validatePhoneNumber(window.$("#ephone"))
                    this.find()
                    e.preventDefault();
                    return false;
                }
            },
            ephoneFocusout() {
                this.ephone = this.validatePhoneNumber(window.$("#ephone"))
            },
            validatePhoneNumber(thes) {
                let phoneInput = thes;
                let iti = window.intlTelInputGlobals.getInstance(phoneInput[0]);
                let valid = iti.isValidNumber();
                if (valid) {
                    this.tips_text = ''
                    this.tips_status = false
                    return iti.getNumber(window.intlTelInputUtils.numberFormat.E164)
                } else {
                    this.tips_text = this.$t("locationPhone.tips")
                    this.tips_status = true
                    return ''
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../style/location-phone";
    .lp-wrap{
        /deep/.iti.iti--allow-dropdown{
            width: 100%;
        }
        .form-box > div > p{
            text-align: left;
        }
        .sms-list{
            >p{
                text-align: left;
            }
        }
        .sms-box{
            >p{
                text-align: left;
            }
        }
    }
    @media (min-width: 960px) {
        .form-box .intl-tel-input input{
            padding-left: 50px;
        }
    }
    @media (max-width: 960px) {
        .footer-box,.navbar {
            display: block;
        }
    }
</style>
