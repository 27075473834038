<template>
    <div class="login-box">
        <div class="banner-content"></div>
        <div class="top">
            <img src="@/assets/images/login-top-img.png" alt="">
            <h1>{{$t("unsubscribe.title")}}</h1>
        </div>
        <div class="form-box processing" v-if="status">
            <div class="tips" v-show="tips">
                <p>
                    {{$t("unsubscribe.tipsErr")}}
                </p>
                <div class="close-box" @click="tips = false">
                    <span></span>
                    <span></span>
                </div>
            </div>
            <p class="txt">
                {{$t("unsubscribe.desc")}}
            </p>
            <input class="email" type="text" placeholder="Enter your email" v-model="email" @keydown="emailKeydowm($event)">
            <a class="enter" @click="cancel()">{{$t("unsubscribe.cancelBtn")}}</a>
        </div>
        <div class="form-box successful" v-else>
            <p class="txt">
                {{$t("unsubscribe.success")}}
            </p>
        </div>
    </div>
</template>

<script>
    import {mapGetters,mapMutations} from "vuex";

    export default {
        name: "cancel-order",
        data(){
            return{
                email: '',
                status: true,
                tips: false
            }
        },
        computed: {
            ...mapGetters(['userInfo'])
        },
        methods:{
            emailKeydowm(e){
                if (e.key == 'Enter') {
                    this.cancel()
                }
            },
            cancel() {
                if (!this.userInfo) {
                    this.toast(this.$t("tips.notLogin"))
                    setTimeout(()=>{
                        this.$router.push({
                            path: '/login'
                        })
                    },2000)
                    return
                }
                let email = this.email.trim().toLowerCase()
                if (!this.isEmailAvailable(email)) {
                    this.toast(this.$t("tips.emailFormatErr"))
                }
                if (this.userInfo.email.toLowerCase() !== email) {
                    setTimeout(()=>{
                        this.hideLoading()
                        this.tips = true
                    })
                    return
                }
                this.loading()
                this.$axios.put('/admin/user/cancelSubscribe').then(res=>{
                    this.hideLoading()
                    if (res.success != '1') {
                        this.toast(res.msg)
                        return
                    }
                    // this.amplitude.getInstance().logEvent('UnSubscribe')
                    this.eventLog('UnSubscribe')
                    this.setUserInfo(null)
                    // log('UnSubscribe')
                    this.status = false
                }).catch(err=>{
                    console.log(err)
                    this.hideLoading()
                    this.toast(err.msg)
                })
            },
            ...mapMutations(['setUserInfo'])
        },
    }
</script>

<style lang="scss" scoped>
    @media (min-width:960px) {
        .login-box {
            width: 100%;
            height: 793px;
            overflow-x: hidden;
            position: relative;
        }
        .login-box .banner-content {
            width: 100%;
            height: 359px;
            overflow-x: hidden;
        }
        .login-box .banner-content::before {
            content: " ";
            width: 150%;
            height: 359px;
            position: absolute;
            top: 0;
            left: -25%;
            border-bottom-left-radius: 50%;
            border-bottom-right-radius: 50%;
            background: #3373B6;
            z-index: -2;
        }
        // .login-box .banner-content::after {
        //     content: " ";
        //     width: 1137px;
        //     height: 359px;
        //     position: absolute;
        //     top: 0;
        //     right: 321px;
        //     background: url('/@/assets/images/header-banner-Texture.png');
        //     z-index: -1;
        // }
        .login-box .top {
            width: 100%;
            position: absolute;
            top: 80px;
        }
        .login-box .top img {
            display: none;
        }
        .login-box .top h1 {
            width: 100%;
            font-size: 60px;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 71px;
            text-align: center;
        }
        .login-box .form-box {
            width: 480px;
            background: #FFFFFF;
            box-shadow: 0px 18px 30px 0px rgba(45, 91, 140, 0.14);
            border-radius: 12px;
            padding: 40px;
            box-sizing: border-box;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 295px;
        }
        .login-box .form-box .txt {
            font-size: 16px;
            font-weight: 400;
            color: #708198;
            line-height: 24px;
            text-align: center;
            margin-bottom: 24px;
        }
        .login-box .form-box input {
            width: 100%;
            height: 42px;
            background: #EDEFF2;
            border-radius: 4px;
            border: none;
            padding: 0 16px;
            font-size: 16px;
            box-sizing: border-box;
        }
        .login-box .form-box input::placeholder {
            font-size: 16px;
            font-weight: 400;
            color: #8594A8;
        }
        .login-box .form-box input:focus {
            box-shadow: 0px 2px .16rem 0px rgba(45, 91, 140, 0.14);
            background: #FFFFFF;
        }
        .login-box .form-box .enter {
            display: block;
            width: 248px;
            height: 60px;
            background: #33B592;
            border-radius: 30px;
            font-size: 18px;
            font-weight: 500;
            color: #FFFFFF;
            text-align: center;
            line-height: 60px;
            margin: 32px auto 0;
            cursor: pointer;
        }
        .login-box .form-box .enter:hover {
            background: #289074;
        }
        .login-box .form-box .tips {
            width: 100%;
            background: #59697C;
            border-radius: 6px;
            color: #FFFFFF;
            padding: 10px 24px;
            box-sizing: border-box;
            font-size: 16px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 21px;
            margin-bottom: 21px;
            position: relative;
        }
        .login-box .form-box .enter.recover-pass {
            width: 248px;
            /* margin-top: .4rem; */
        }
        .login-box.repeat {
            display: none;
        }
        .footer-box {
            margin-top: 10%;
        }
    }

    @media (max-width: 960px) {
        body {
            background: #F6F7F8;
        }
        .login-box {
            width: 6.86rem;
            background: #FFFFFF;
            box-shadow: 0px 18px 30px 0px rgba(45, 91, 140, 0.14);
            border-radius: 12px;
            margin: .94rem auto;
        }
        .login-box.repeat {
            display: none;
        }
        .login-box .top {
            width: 100%;
            position: relative;
        }
        .login-box .top img {
            width: 100%;
            height: 2.25rem;
        }
        .login-box .top h1 {
            width: 100%;
            text-align: center;
            font-size: .48rem;
            font-weight: 600;
            color: #FFFFFF;
            line-height: .57rem;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
        .login-box .form-box {
            width: 100%;
            padding: .42rem .43rem .4rem;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
        }
        .login-box .form-box .enter {
            width: 3.60rem;
            height: .88rem;
            background: #33B592;
            border-radius: .44rem;
            font-size: .32rem;
            font-weight: 500;
            color: #FFFFFF;
            text-align: center;
            line-height: .88rem;
            margin: .4rem auto 0;
            cursor: pointer;
        }
        .login-box .form-box .enter:active {
            background: #289074;
        }
        .login-box .form-box .tips {
            width: 100%;
            background: #59697C;
            border-radius: .12rem;
            color: #FFFFFF;
            padding: .08rem .48rem;
            box-sizing: border-box;
            font-size: .28rem;
            font-weight: 500;
            color: #FFFFFF;
            line-height: .42rem;
            margin-bottom: .42rem;
            position: relative;
        }
        .close-box {
            width: .24rem;
            height: .24rem;
            position: absolute;
            top: .16rem;
            right: .16rem;
        }
        .close-box span {
            width: 100%;
            height: 2px;
            border-radius: 4px;
            transform: rotate(-45deg);
            position: absolute;
            left: 0;
            top: .12rem;
            background: #FFFFFF;
        }
        .close-box span:nth-child(2) {
            transform: rotate(45deg);
        }
        .login-box .form-box .txt {
            font-size: .28rem;
            font-weight: 400;
            color: #708198;
            line-height: .42rem;
            text-align: center;
        }
        .login-box .form-box input {
            width: 100%;
            height: .72rem;
            background: #EDEFF2;
            border-radius: .12rem;
            border: none;
            padding: 0 .30rem;
            box-sizing: border-box;
            margin-top: .4rem;
        }
        .login-box .form-box input:focus {
            box-shadow: 0px 2px .16rem 0px rgba(45, 91, 140, 0.14);
            background: #FFFFFF;
        }
        .login-box .form-box input::placeholder {
            font-size: .28rem;
            font-weight: 400;
            color: #8594A8;
        }
    }

</style>
