<template>
    <div class="pp-wrap">
        <div class="top">
            <div class="banner-content"></div>
            <div class="content">
                <h1>{{$t("privacyPolicy.title")}}</h1>
                <p>{{$t("privacyPolicy.update")}}</p>
            </div>
        </div>
        <div class="txt-box">
            <div class="txt">
                <div class="disc">
                    <h4>{{$t("privacyPolicy.description.title")}}</h4>
                    <p>{{$t("privacyPolicy.description.one")}}</p>
                    <p>{{$t("privacyPolicy.description.two")}}</p>
                    <p>{{$t("privacyPolicy.description.three")}}</p>
                    <p>{{$t("privacyPolicy.description.four")}}</p>
                    <p>{{$t("privacyPolicy.description.five")}}</p>
                </div>
                <div class="disc">
                    <h4>{{$t("privacyPolicy.informationCollected.title")}}</h4>
                    <p>{{$t("privacyPolicy.informationCollected.descOne")}}</p>
                    <p>{{$t("privacyPolicy.informationCollected.descTwo")}}</p>
                    <p>{{$t("privacyPolicy.informationCollected.descThree")}}</p>
                    <p>{{$t("privacyPolicy.informationCollected.one.title")}}</p>
                    <p>{{$t("privacyPolicy.informationCollected.one.desc")}}</p>
                    <ul>
                        <li>{{$t("privacyPolicy.informationCollected.one.one")}}</li>
                        <li>{{$t("privacyPolicy.informationCollected.one.two")}}</li>
                        <li>{{$t("privacyPolicy.informationCollected.one.three")}}</li>
                        <li>{{$t("privacyPolicy.informationCollected.one.four")}}</li>
                        <li v-html='$t("privacyPolicy.informationCollected.one.five")'></li>
                        <li v-html='$t("privacyPolicy.informationCollected.one.six")'></li>
                    </ul>
                    <p>{{$t("privacyPolicy.informationCollected.two.title")}}</p>
                    <p>{{$t("privacyPolicy.informationCollected.one.desc")}}</p>
                    <ul>
                        <li>{{$t("privacyPolicy.informationCollected.two.one")}}</li>
                        <li>{{$t("privacyPolicy.informationCollected.two.two")}}</li>
                        <li>{{$t("privacyPolicy.informationCollected.two.three")}}</li>
                        <li>{{$t("privacyPolicy.informationCollected.two.four")}}</li>
                        <li>{{$t("privacyPolicy.informationCollected.two.five")}}</li>
                    </ul>
                    <p>{{$t("privacyPolicy.informationCollected.three.title")}}</p>
                    <p>{{$t("privacyPolicy.informationCollected.one.desc")}}
                    </p>
                    <ul>
                        <li>{{$t("privacyPolicy.informationCollected.three.one")}}</li>
                        <li>{{$t("privacyPolicy.informationCollected.three.two")}}</li>
                    </ul>
                    <p>{{$t("privacyPolicy.informationCollected.four.title")}}</p>
                    <p>{{$t("privacyPolicy.informationCollected.four.one")}}</p>
                    <p>{{$t("privacyPolicy.informationCollected.four.two")}}</p>
                    <p>{{$t("privacyPolicy.informationCollected.four.three")}} <a href="mailto:Findmyphone@chamspy.com">Findmyphone@chamspy.com</a>
                    </p>
                    <p>{{$t("privacyPolicy.informationCollected.four.four")}}</p>
                    <p v-html='$t("privacyPolicy.informationCollected.four.five")'></p>
                </div>
                <div class="disc">
                    <h4>{{$t("privacyPolicy.rights.title")}}</h4>
                    <p>{{$t("privacyPolicy.rights.one")}}</p>
                    <p>{{$t("privacyPolicy.rights.two")}}</p>
                    <p>{{$t("privacyPolicy.rights.three")}} <a href="mailto:Findmyphone@chamspy.com">Findmyphone@chamspy.com</a> .
                    </p>
                    <p>{{$t("privacyPolicy.rights.four")}}</p>
                    <p v-html='$t("privacyPolicy.rights.six")'></p>
                    <p>{{$t("privacyPolicy.rights.five")}}</p>
                </div>
                <div class="disc">
                    <h4>{{$t("privacyPolicy.use.title")}}
                    </h4>
                    <p>{{$t("privacyPolicy.use.one")}}
                    </p>
                    <ul>
                        <li>{{$t("privacyPolicy.use.two")}}
                        </li>
                        <li>{{$t("privacyPolicy.use.three")}}</li>
                        <li>{{$t("privacyPolicy.use.four")}}</li>
                        <li>{{$t("privacyPolicy.use.five")}}
                        </li>
                        <li>{{$t("privacyPolicy.use.six")}}</li>
                    </ul>
                    <p v-html='$t("privacyPolicy.use.seven")'></p>
                    <ul>
                        <li>{{$t("privacyPolicy.use.eight")}}</li>
                        <li>{{$t("privacyPolicy.use.nine")}}</li>
                    </ul>
                    <p>{{$t("privacyPolicy.use.ten")}}</p>
                </div>
                <div class="disc">
                    <h4>{{$t("privacyPolicy.delete.title")}}</h4>
                    <p>{{$t("privacyPolicy.delete.one")}} <a href="mailto:Findmyphone@chamspy.com">Findmyphone@chamspy.com</a>
                    </p>
                    <p>{{$t("privacyPolicy.delete.two")}}</p>
                </div>
                <div class="disc">
                    <h4>{{$t("privacyPolicy.data.title")}}
                    </h4>
                    <p>{{$t("privacyPolicy.data.one")}}</p>
                    <p>{{$t("privacyPolicy.data.two")}}</p>
                    <p>{{$t("privacyPolicy.data.three")}}</p>
                    <p>{{$t("privacyPolicy.data.four")}}</p>
                    <p>{{$t("privacyPolicy.data.five")}}</p>
                    <p>{{$t("privacyPolicy.data.six")}}</p>
                    <p>{{$t("privacyPolicy.data.seven")}}</p>
                    <p>{{$t("privacyPolicy.data.eight")}}</p>
                    <p>{{$t("privacyPolicy.data.nine")}}</p>
                </div>
                <div class="disc">
                    <h4>{{$t("privacyPolicy.security.title")}}</h4>
                    <p>{{$t("privacyPolicy.security.one")}}</p>
                    <p>{{$t("privacyPolicy.security.two")}}</p>
                    <p>{{$t("privacyPolicy.security.three")}}</p>
                    <p>{{$t("privacyPolicy.security.four")}}</p>
                </div>
                <div class="disc">
                    <h4>{{$t("privacyPolicy.changes.title")}}</h4>
                    <p>{{$t("privacyPolicy.changes.one")}}</p>
                    <p>{{$t("privacyPolicy.changes.two")}}</p>
                </div>
                <div class="disc">
                    <h4>{{$t("privacyPolicy.contact.title")}}</h4>
                    <p>{{$t("privacyPolicy.contact.one")}}</p>
                    <p>{{$t("login.e_amil")}}: <a href="mailto:Findmyphone@chamspy.com">Findmyphone@chamspy.com</a>
                    </p>
                    <p>{{$t("privacyPolicy.contact.two")}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "privacy-Policy",
        created() {
            // this.amplitude.getInstance().logEvent('PrivacyPolicyScreen')
        }
    }
</script>

<style lang="scss" scoped>
    @import "../style/text";
    .pp-wrap{
        text-align: left;
    }
</style>
