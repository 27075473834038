<template>
    <div class="map-wrap">
        <div class="map-box">
            <div id="map"></div>
            <div class="bottom-box">
                <div class="address-box before-sending" v-show="schedule === 0">
                    <img src="@/assets/images/icon_gps.png" alt="">
                    <div>
                        <h4 class="">{{$t("map.locaOf")}} <span class="phone-number">{{phoneNumber}}</span></h4>
                        <p v-show="address_txt !== ''">
                            XXXXX,<span class="address-txt">{{address_txt}}</span>
                        </p>
                    </div>
                </div>
                <p class="before-sending" v-show="schedule === 0">{{$t("map.desc")}}</p>
                <p class="wait" v-show="schedule === 1">
                    {{$t("map.wait")}}
                </p>
                <div class="result" v-show="schedule === 2">
                    <div class="address-box">
                        <img src="@/assets/images/icon_gps.png" alt="">
                        <div>
                            <h4 class="result">{{$t("map.locaOf")}} <span class="phone-number">{{phoneNumber}}</span></h4>
                            <p class="time result">{{time}}</p>
                        </div>
                    </div>
                    <p class="location-txt result">{{location_txt}}</p>
                </div>
                <a class="share-btn" @click="share_status = true">{{$t("map.sendUP")}}</a>
                <a class="copy-btn" @click="copyBtn()">{{$t("map.copyLink")}}</a>
            </div>
        </div>

        <div class="back" @click="back">
            <span></span>
            <span></span>
        </div>

        <div class="share-box" :class="share_status?'active':''" @click="share_status = false">
            <div class="share" :style="{transform: share_status?'translateY(0)':'translateY(100%)'}"
                 @click.stop.prevent="share_status = true">
                <ul>
                    <li class="share-sms" v-for="(item,index) in share_list" :key="index" @click.stop.prevent="share(index)">
                        <img :src="item.img" alt="">
                        <span>{{item.name}}</span>
                    </li>
                </ul>
            </div>
        </div>
        <input id="copyInput" type="text" v-model="copyUrl">
    </div>
</template>

<script>
    import {Loader} from 'google-maps'

    export default {
        name: "myMap",
        data() {
            return {
                btn_status: 0,
                schedule: 0,
                map: null,
                req_time: -1,
                google: null,
                first_marker: null,
                first_circle: null,
                time: '',
                address_txt: '',
                location_txt: '',
                share_status: false,
                share_list: [
                    {
                        img: require('../assets/images/map-share-icon-sms.png'),
                        name: this.$t("map.smsUP"),
                        open: '',
                        alias: 'SMS',
                    },
                    {
                        img: require('../assets/images/map-share-icon-Email.png'),
                        name: this.$t("map.email"),
                        open: '',
                        alias: 'Mail',
                    },
                    {
                        img: require('../assets/images/map-share-icon-Facebook-Messenger.png'),
                        name: this.$t("map.messenger"),
                        open: 'fb-messenger://',
                        alias: 'Messenger',
                    },
                    {
                        img: require('../assets/images/map-share-icon-WhatsApp.png'),
                        name: this.$t("map.whatsApp"),
                        open: 'whatsapp://',
                        alias: 'WhatsApp'
                    },
                    {
                        img: require('../assets/images/map-share-icon-Copy.png'),
                        name: this.$t("map.copyLink"),
                        open: '',
                        alias: 'CopyLink'
                    },
                ],
                copyUrl: '',
                logEventFlag: 0,
                phoneNumber: localStorage.getItem('phoneNumber') || 0,
                logEventSource: this.$getQueryString('logEventSource') || 'HomeScreen',
                getPhoneLocationTimeout: null
            }
        },
        created() {
            this.eventLog('map_page')
            if (location.host === "best.find-my-phone.org") {
                this.copyUrl = this.addChannel('http://bit.do/fQ9Vx?id=' + sessionStorage.getItem('location_phone_id'))
            }else {
                this.copyUrl = this.addChannel('http://bit.do/fRiNZ?id=' + sessionStorage.getItem('location_phone_id'))
            }
        },
        async mounted() {
            console.log(window.innerWidth)
            if (window.innerWidth<960) {
                document.querySelector('.navbar').style.display = 'none'
            }

            if (!sessionStorage.getItem('location_phone_id')) {
                this.toast(this.$t("tips.rePhoneNumberToLocate"))
                setTimeout(()=>{
                    this.$router.push({
                        path: '/location-phone'
                    })
                },2000)
                return
            }

            document.querySelector('.footer-box').style.display = 'none'
            try {
                this.loading()
                const loader = new Loader('AIzaSyAEfCN_QFZ2-EDsXeyuesxozpLaL73mYT4')
                this.google = await loader.load()
                this.map = new this.google.maps.Map(document.getElementById('map'), {
                    center: {
                        lat: 51.50,
                        lng: 1.50
                    },
                    mapTypeId: this.google.maps.MapTypeId.ROADMAP,
                    zoom: 12
                })
                this.map.setOptions({
                    draggable: false,
                    zoomControl: false
                });
                const result = await this.$axios.get(`/spyContent/phoneLocation/${sessionStorage.getItem('location_phone_id')}`)
                if (result.success != '1') {
                    this.hideLoading()
                    console.log(result)
                    this.toast(result.msg)
                    return
                }

                this.hideLoading()
                let json = JSON.parse(result.obj.json);
                this.req_time = json.time
                let latLng = new this.google.maps.LatLng(json.lat, json.lng)
                this.first_marker = new this.google.maps.Marker({
                    position: latLng,
                });
                let obj = {
                    center: latLng,
                    radius: 1000,
                    strokeOpacity: 0.4,
                    strokeWeight: 2,
                    fillOpacity: 0.3,
                    strokeColor: "#EB665B",
                    fillColor: "#EB665B",
                    map: this.map
                }
                this.first_circle = new this.google.maps.Circle(obj)
                this.first_circle.setMap(this.first_circle)
                this.first_marker.setMap(this.map);
                this.map.panTo(latLng)
                this.time = new Date(json.time)
                let geocoder = new this.google.maps.Geocoder();
                geocoder.geocode({
                    'latLng': latLng
                }, (results, status) => {
                    if (status === this.google.maps.GeocoderStatus.OK) {
                        if (results[0]) {
                            console.log(results)
                            this.address_txt = results[0].formatted_address
                            this.location_txt = results[4].formatted_address
                        }
                    }
                });
            }catch (e) {
                console.log(e)
                this.hideLoading()
                this.toast(this.$t("tips.err"))
            }
        },
        destroyed() {
            document.querySelector('.navbar').style.display = 'block'
            document.querySelector('.footer-box').style.display = 'block'
            this.getPhoneLocation(false)

        },
        methods: {
            copyBtn() {
                if (this.logEventFlag === 0) {
                    // this.amplitude.getInstance().logEvent('LocateResultsScreen',{
                    //     Type: 'City',
                    //     Source: this.logEventSource
                    // })
                    this.eventLog('LocateResultsScreen-City-'+this.logEventSource)
                    this.logEventFlag += 1
                }
                // this.amplitude.getInstance().logEvent('Sharing',{
                //     Platform: 'CopyLink'
                // })
                this.eventLog('Sharing-CopyLink')
                this.copyNum();
                window.firebase.analytics().logEvent('share_location')
                this.getPhoneLocation()
                this.schedule = 1
            },
            share(index) {
                console.log(index)
                this.eventLog('share')
                if (this.logEventFlag === 0) {
                    // this.amplitude.getInstance().logEvent('LocateResultsScreen',{
                    //     Type: 'City',
                    //     Source: this.logEventSource
                    // })
                    this.eventLog('LocateResultsScreen-City-'+this.logEventSource)
                    this.logEventFlag += 1
                }
                let item = this.share_list[index]
                // this.amplitude.getInstance().logEvent('Sharing',{
                //     Platform: item.alias
                // })
                this.eventLog('Sharing-'+item.alias)
                if (item.alias === 'CopyLink') {
                    this.copyNum();
                } else if (item.alias === 'SMS') {
                    let a = document.createElement('a')
                    if (this.IsAndroid()) {
                        a.setAttribute('href', 'sms:?body=Someone is looking for you, please click this link: ' + this.addChannel(location.origin + '/getLocation?id=' + sessionStorage.getItem('location_phone_id')))
                    } else {
                        a.setAttribute('href', 'sms:&body=Someone is looking for you, please click this link: ' + this.addChannel(location.origin + '/getLocation?id=' + sessionStorage.getItem('location_phone_id')))
                    }
                    a.click()
                    a.remove()
                } else if (item.alias === 'Email') {
                    let a = document.createElement('a')
                    a.setAttribute('href', 'mailto:?subject=Get location&body=Someone is looking for you, please click this link: ' + this.addChannel(location.origin + '/getLocation.html?id=' + sessionStorage.getItem('location_phone_id')))
                    a.click()
                    a.remove()
                } else if (item.alias === 'Messenger' || item.alias === 'WhatsApp') {
                    location.href = item.open
                    this.copyNum();
                    setTimeout(()=>{
                        this.toast(this.$t("tips.noDownApp"))
                    },2000)
                }
                this.share_status = false
                this.schedule = 1
                window.firebase.analytics().logEvent('share_location')
                this.getPhoneLocation()
            },
            back(){
                this.$router.push({
                    path: '/location-phone'
                })
            },
            getPhoneLocation(status = true) {
                if (!status) {
                    clearTimeout(this.getPhoneLocationTimeout)
                    return
                }
                // this.loading()
                this.$axios.get(`/spyContent/phoneLocation/${sessionStorage.getItem('location_phone_id')}`).then(res=>{
                    if (res.success != '1') {
                        this.toast(res.msg)
                        return
                    }
                    let json = JSON.parse(res.obj.json)
                    if (this.req_time === -1 || json.time == undefined || this.req_time >= json.time) {
                        this.getPhoneLocationTimeout = setTimeout(()=>{
                            this.getPhoneLocation()
                        },1500)
                    } else {
                        this.hideLoading()
                        this.schedule = 2

                        this.first_marker.setMap(null);
                        this.first_circle.setMap(null);
                        let latLng = new this.google.maps.LatLng(json.lat, json.lng)
                        let marker = new this.google.maps.Marker({
                            position: latLng,
                        });
                        let obj = {
                            center: latLng,
                            radius: 1000,
                            strokeOpacity: 0.4,
                            strokeWeight: 2,
                            fillOpacity: 0.3,
                            strokeColor: "#EB665B",
                            fillColor: "#EB665B",
                            map: this.map
                        }
                        let circle = new this.google.maps.Circle(obj)
                        circle.setMap(circle)
                        marker.setMap(this.map);
                        this.map.panTo(latLng)
                        this.map.setOptions({
                            draggable: true,
                            zoomControl: true
                        });
                        this.time = new Date(json.time)
                        window.$('.share-btn').css('display', 'none')
                        window.$('.copy-btn').css('display', 'none')
                        // this.amplitude.getInstance().logEvent('LocateResultsScreen',{
                        //     Type: 'Location',
                        //     Source: this.logEventSource
                        // })
                        this.eventLog('LocateResultsScreen-Location'+this.logEventSource)
                        window.firebase.analytics().logEvent('get_location')
                        let geocoder = new this.google.maps.Geocoder();
                        geocoder.geocode({
                            'latLng': latLng
                        }, (results, status)=>{
                            if (status === this.google.maps.GeocoderStatus.OK) {
                                if (results[0]) {
                                    this.location_txt = results[0].formatted_address
                                    console.log(results[0])
                                }
                            }
                        });
                    }
                }).catch(err=>{
                    this.toast(err.msg)
                })
            },
            copyNum() {
                let NumClip = document.getElementById("copyInput");
                let NValue = this.copyUrl;
                let valueLength = NValue.length;
                this.selectText(NumClip, 0, valueLength);
                if (document.execCommand('copy', false, null)) {
                    document.execCommand('copy', false, null)
                    this.toast(this.$t("tips.shareLinkSuccess"))
                } else {
                    this.toast(this.$t("tips.copyFailed"))
                }
                NumClip.blur();
            },
            selectText(textbox, startIndex, stopIndex) {
                if (textbox.createTextRange) { //ie
                    let range = textbox.createTextRange();
                    range.collapse(true);
                    range.moveStart('character', startIndex);
                    range.moveEnd('character', stopIndex - startIndex);
                    range.select();
                } else {
                    textbox.setSelectionRange(startIndex, stopIndex);
                    textbox.focus();
                }
            },
            addChannel(url) {
                let u = url
                if (localStorage.getItem('global_method') != null) {
                    let s = url.indexOf("?") == -1 ? '?' : '&'
                    u = url + s + 'methods=' + localStorage.getItem('global_method')
                }
                return u
            }
        },
    }
</script>

<style lang="scss" scoped>
    .footer-box {
        display: none;
    }
    #copyInput {
        position: fixed;
        left: -10000px;
    }
    /deep/.navbar {
        display: none !important;
    }
    @media (min-width: 960px) {
        #app {
            position: relative;
        }
        .navbar{
            display: block !important;
        }
        .map-box {
            width: 1124px;
            margin: 0 auto;
        }
        #map {
            width: 100%;
            height: 780px;
            margin-top: 20px;
        }
        .map-box .bottom-box .address-box {
            width: 100%;
            display: flex;
            align-items: center;
            margin-bottom: 6px;
            margin-top: 24px;
        }
        .map-box .bottom-box .address-box > img {
            display: none;
        }
        .map-box .bottom-box .address-box > div {
            flex: 1;
            display: flex;
            justify-content: center;
        }
        .map-box .bottom-box .address-box > div h4 {
            font-size: 16px;
            font-weight: 500;
            color: #333333;
            line-height: 19px;
        }
        .map-box .bottom-box .address-box > div p {
            font-size: 16px;
            font-weight: 500;
            color: #333333;
            line-height: 19px;
            text-align: center;
            margin-left: 15px;
        }
        .before-sending {
            text-align: center;
        }
        .map-box .bottom-box a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 248px;
            height: 60px;
            background: #33B592;
            border-radius: 30px;
            font-size: 18px;
            font-weight: 500;
            color: #FFFFFF;
            cursor: pointer;
            margin: 24px auto;
        }
        .map-box .bottom-box a:hover {
            background: #289074;
        }
        .map-box .bottom-box a.share-btn {
            display: none;
        }
        .share-box {
            display: none;
        }
        .wait {
            margin-top: 24px;
            width: 100%;
            width: 1124px;
            font-size: 14px;
            font-weight: 400;
            color: #444444;
            line-height: 22px;
            text-align: center;
        }
        .location-txt {
            font-size: 16px;
            font-weight: 500;
            color: #333333;
            line-height: 24px;
            text-align: center;
        }
    }

    @media (max-width: 960px) {
        #app {
            position: relative;
        }
        .map-box {
            width: 100%;
            height: 100vh;
            overflow: hidden;
            display: flex;
            flex-direction: column;
        }
        #map {
            width: 100%;
            flex: 1;
        }
        .map-box .bottom-box {
            width: 100%;
            background: #FFFFFF;
            padding: .52rem .32rem;
            box-sizing: border-box;
        }
        .map-box .bottom-box h4 {
            font-size: .32rem;
            font-weight: 600;
            color: #333333;
            line-height: .38rem;
            margin-bottom: .23rem;
        }
        .map-box .bottom-box p {
            width: 100%;
            font-size: .28rem;
            font-weight: 400;
            color: #444444;
            line-height: .42rem;
            margin-bottom: .14rem;
        }
        .map-box .bottom-box a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 4.8rem;
            height: .88rem;
            background: #33B592;
            border-radius: .44rem;
            font-size: .32rem;
            font-weight: 500;
            color: #FFFFFF;
            margin: 0 auto;
            cursor: pointer;
        }
        .map-box .bottom-box a:active {
            background: #289074;
        }
        .map-box .bottom-box a.copy-btn {
            display: none;
        }
        .map-box .bottom-box p.time {
            font-size: .28rem;
            font-weight: 400;
            color: #666666;
            line-height: .42rem;
        }
        .before-sending,.wait,.result {
            text-align: left;
        }
        .share-box {
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: rgba(0, 0, 0, .5);
            z-index: -1;
            opacity: 0;
            visibility: hidden;
            transition: all .3s;
        }
        .share-box.active {
            z-index: 1000;
            opacity: 1;
            visibility: visible;
        }
        .share-box .share {
            width: 7.14rem;
            /* height: 644px; */
            background: #FFFFFF;
            border-radius: .44rem;
            opacity: 0.96;
            position: absolute;
            left: .18rem;
            bottom: .28rem;
            padding: .58rem 0 .1rem;
            transform: translateY(100%);
            transition: all .3s;
        }
        .share-box .share ul {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
        }
        .share-box .share ul li {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 25%;
            margin-bottom: .48rem;
        }
        .share-box .share ul li:active {
            background: rgba(0, 0, 0, .2);
        }
        .share-box .share ul li img {
            width: .96rem;
            height: .96rem;
            margin-bottom: .18rem;
        }
        .share-box .share ul li span {
            font-size: .26rem;
            font-weight: 400;
            color: #708198;
            line-height: .30rem;
        }
        .back {
            background: none rgb(255, 255, 255);
            border: 0px;
            margin: 10px;
            padding: 0px;
            position: absolute;
            cursor: pointer;
            user-select: none;
            border-radius: 2px;
            height: 40px;
            width: 40px;
            box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
            overflow: hidden;
            top: 50px;
            right: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
        }
        .back span {
            width: 20px;
            height: 3px;
            background: #414c5a;
            border-radius: 4px;
            transition: all .3s;
        }
        .back span:nth-child(1) {
            transform: rotate(-45deg);
            position: absolute;
            left: 10px;
            top: 18px;
        }
        .back span:nth-child(2) {
            transform: rotate(45deg);
            position: absolute;
            left: 10px;
            top: 18px;
        }

        .map-box .bottom-box .address-box {
            width: 100%;
            display: flex;
            align-items: center;
            margin-bottom: .32rem;
        }
        .map-box .bottom-box .address-box > img {
            width: .53rem;
            height: .62rem;
            margin-right: .15rem;
            margin-bottom: .04rem;
        }
        .map-box .bottom-box .address-box > div h4 {
            font-size: .32rem;
            font-weight: 500;
            color: #333333;
            line-height: .38rem;
            margin-bottom: .06rem;
        }
        .map-box .bottom-box .address-box > div p {
            font-size: .28rem;
            font-weight: 400;
            color: #666666;
            line-height: .42rem;
            margin-bottom: 0;
            text-align: left;
        }
    }
</style>
