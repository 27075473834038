<template>
    <div class="login-wrap">
        <div class="login-box">
            <div class="banner-content"></div>
            <div class="top">
                <img src="@/assets/images/login-top-img.png" alt="">
                <h1>{{$t("login.title")}}</h1>
            </div>
            <div class="form-box">
                <div class="input-box">
                    <p>{{$t("login.e_amil")}}</p>
                    <input type="text" class="email" v-model="email" @keydown="phoneKeydown($event)">
                </div>
                <div class="input-box">
                    <p>{{$t("login.passwordUP")}}</p>
                    <input type="password" class="password" v-model="password" ref="password" @keydown="loginKeydown($event)">
                </div>
                <a @click="goForgetPassword" class="forget">{{$t("login.forgetPassword")}}</a>
                <a class="enter" @click="login_click()">{{$t("login.enterUP")}}</a>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapMutations, mapGetters} from 'vuex'
    export default {
        name: "login",
        data() {
            return{
                email: '',
                password: ''
            }
        },
        computed: {
            ...mapGetters(['userInfo'])
        },
        created() {
            if (this.userInfo) {
                this.$router.push({
                    path: '/location-phone',
                })
            }
        },
        methods:{
            login_click(){
                let email = this.email.trim()
                let password = this.password.trim()
                if (email === '' || password === '') {
                    this.toast(this.$t("tips.emailAndPasswordNoEmpty"))
                    return
                }
                if (!this.isEmailAvailable(email)) {
                    this.toast(this.$t("tips.emailIncorrect"))
                    return
                }
                if (password.indexOf(" ") !== -1) {
                    this.toast(this.$t("tips.passWordNoSpaces"))
                    return
                }
                if (password.length < 6) {
                    this.toast(this.$t("tips.passWordNoSix"))
                    return
                }
                this.eventLog('login')
                this.loading()
                this.$axios.post('/admin/userSession',{
                    email: email,
                    password: password
                }).then(res=>{
                    this.hideLoading()
                    if (res.success != '1') {
                        this.toast(res.msg)
                        return
                    }
                    // this.amplitude.setUserId(email);
                    this.setUserInfo(res.obj)
                    // localStorage.setItem('userInfo', JSON.stringify(res.obj))
                    this.$router.push({
                        path: '/location-phone'
                    })
                }).catch(err=>{
                    this.toast(err.msg)
                    this.hideLoading()
                })
            },
            loginKeydown(e){
                if (e.key == 'Enter') {
                    this.login_click()
                }
            },
            phoneKeydown(e){
                if (e.key == 'Enter') {
                    if (!this.isEmailAvailable(this.email.trim())){
                        this.toast(this.$t("tips.emailIncorrect"))
                    }else {
                        this.$refs.password.focus()
                    }
                }
            },
            goForgetPassword() {
                this.$router.push({
                    path: '/forget-password'
                })
            },
            ...mapMutations(['setUserInfo'])
        }
    }
</script>

<style lang="scss" scoped>
    @import "../style/login";

</style>
