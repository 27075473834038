<template>
    <div class="tou-wrap">
        <div class="top">
            <div class="banner-content"></div>
            <div class="content">
                <h1>{{$t("terms.title")}}</h1>
                <p>{{$t("terms.update")}}</p>
            </div>
        </div>
        <div class="txt-box">
            <div class="txt">
                <div class="disc">
                    <h4>{{$t("terms.ownership.title")}}</h4>
                    <p>{{$t("terms.ownership.desc")}}</p>
                </div>
                <div class="disc">
                    <h4>{{$t("terms.acceptance.title")}}</h4>
                    <p>{{$t("terms.acceptance.one")}}</p>
                    <p>{{$t("terms.acceptance.two")}}</p>
                    <p>{{$t("terms.acceptance.three")}}</p>
                </div>
                <div class="disc">
                    <h4>{{$t("terms.description.title")}}</h4>
                    <p>{{$t("terms.description.one")}}</p>
                    <p>{{$t("terms.description.two")}}</p>
                    <p>{{$t("terms.description.three")}}</p>
                </div>
                <div class="disc">
                    <h4>{{$t("terms.external.title")}}</h4>
                    <p>{{$t("terms.external.one")}}</p>
                    <p>{{$t("terms.external.two")}}</p>
                    <p>{{$t("terms.external.three")}}</p>
                </div>
                <div class="disc">
                    <h4>{{$t("terms.age.title")}}</h4>
                    <p>{{$t("terms.age.one")}}</p>
                    <p>{{$t("terms.age.two")}}</p>
                </div>
                <div class="disc">
                    <h4>{{$t("terms.intellectual.title")}}</h4>
                    <p>{{$t("terms.intellectual.one")}}</p>
                    <p>{{$t("terms.intellectual.two")}}</p>
                    <p>{{$t("terms.intellectual.three")}}</p>
                </div>
                <div class="disc">
                    <h4>{{$t("terms.user.title")}}</h4>
                    <p>{{$t("terms.user.one")}}</p>
                    <p>{{$t("terms.user.two")}}</p>
                    <p>{{$t("terms.user.three")}}</p>
                    <p>{{$t("terms.user.four")}}</p>
                    <p>{{$t("terms.user.five")}}</p>
                </div>
                <div class="disc">
                    <h4>{{$t("terms.price.title")}}</h4>
                    <p>{{$t("terms.price.one")}}</p>
                </div>
                <div class="disc">
                    <h4>{{$t("terms.payment.title")}}</h4>
                    <p>{{$t("terms.payment.one")}}</p>
                    <p>{{$t("terms.payment.two")}}</p>
                    <p>{{$t("terms.payment.three")}}</p>
                </div>
                <div class="disc">
                    <h4>{{$t("terms.validity.title")}}</h4>
                    <p>{{$t("terms.validity.one")}}</p>
                    <p>{{$t("terms.validity.two")}}</p>
                    <p>{{$t("terms.validity.three")}}</p>
                    <p>{{$t("terms.validity.four")}}</p>
                </div>
                <div class="disc">
                    <h4>{{$t("terms.guarantees.title")}}</h4>
                    <p>{{$t("terms.guarantees.one")}}</p>
                    <p>{{$t("terms.guarantees.two")}}</p>
                    <p>{{$t("terms.guarantees.three")}}</p>
                </div>
                <div class="disc">
                    <h4>{{$t("terms.responsibility.title")}}</h4>
                    <p>{{$t("terms.responsibility.one")}}</p>
                    <p>{{$t("terms.responsibility.two")}}</p>
                </div>
                <div class="disc">
                    <h4>{{$t("terms.security.title")}}</h4>
                    <p>{{$t("terms.security.one")}}</p>
                    <p>{{$t("terms.security.two")}}</p>
                    <p v-html='$t("terms.security.three")'></p>
                    <p>{{$t("terms.security.four")}}</p>
                    <p>{{$t("terms.security.five")}}</p>
                </div>
                <div class="disc">
                    <h4>{{$t("terms.subscription.title")}}
                    </h4>
                    <p>{{$t("terms.subscription.one")}}
                    </p>
                    <p>{{$t("terms.subscription.two")}}
                    </p>
                    <p>{{$t("terms.subscription.three")}}
                    </p>
                    <p>{{$t("terms.subscription.four")}}
                    </p>
                    <p>{{$t("terms.subscription.five")}} <a href="mailto:Findmyphone@chamspy.com">Findmyphone@chamspy.com</a>
                    </p>
                    <p>{{$t("terms.subscription.six")}}
                    </p>
                </div>
                <div class="disc">
                    <h4>{{$t("terms.withdrawal.title")}}</h4>
                    <p>{{$t("terms.withdrawal.one")}}</p>
                    <p>{{$t("terms.withdrawal.two")}}</p>
                </div>
                <div class="disc">
                    <h4>{{$t("terms.modifications.title")}}</h4>
                    <p>{{$t("terms.modifications.one")}}</p>
                    <p>{{$t("terms.modifications.two")}}</p>
                    <p>{{$t("terms.modifications.three")}}</p>
                </div>
                <div class="disc">
                    <h4>{{$t("terms.claims.title")}}
                    </h4>
                    <p>{{$t("terms.claims.one")}}</p>
                </div>
                <div class="disc">
                    <h4>{{$t("terms.contact.title")}}
                    </h4>
                    <p>{{$t("terms.contact.one")}} <a href="mailto:Findmyphone@chamspy.com">Findmyphone@chamspy.com</a>
                    </p>
                    <p>{{$t("terms.contact.two")}}
                    </p>
                    <p>{{$t("terms.contact.three")}} 852-27108200
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "terms-of-use",
        created() {
            // this.amplitude.getInstance().logEvent('TermsOfUseScreen')
        }
    }
</script>

<style lang="scss" scoped>
@import "../style/text";
    .tou-wrap{
        text-align: left;
    }
</style>
