<template>
  <div class="cookie-wrap">
    <div class="top">
      <div class="banner-content"></div>
      <div class="content">
        <h1>{{$t("cookie.title")}}</h1>
        <p>{{$t("cookie.update")}}</p>
      </div>
    </div>
    <div class="txt-box">
      <div class="txt">
        <div class="disc">
          <h4>{{$t("cookie.summary.title")}}</h4>
          <ul>
            {{$t("cookie.summary.one")}}
          </ul>
          <ul>
            {{$t("cookie.summary.two")}}
          </ul>
          <ul>
            {{$t("cookie.summary.three")}}
          </ul>
          <ul>{{$t("cookie.summary.four")}}</ul>
        </div>
        <div class="disc">
          <h4>{{$t("cookie.how.title")}}</h4>
          <p>{{$t("cookie.how.desc")}}</p>
          <ul>
            <li>
              <a
                href="https://www.google.com/url?q=http://windows.microsoft.com/es-es/windows-10/edge-privacy-faq&sa=D&ust=1491479807280000&usg=AFQjCNExWkpVolVZ5WmGkhMrRq4WoOOmZg"
                >{{$t("cookie.how.one")}}</a>
            </li>
            <li>
              <a
                href="https://www.google.com/url?q=https://support.google.com/chrome/answer/95647?hl=es&sa=D&ust=1491479807282000&usg=AFQjCNHVfPwTJqjehsKZwLpTTdx3t6PtEA"
                >{{$t("cookie.how.two")}}</a
              >
            </li>
            <li>
              <a
                href="https://www.google.com/url?q=https://support.google.com/chrome/answer/2392971?hl=es&sa=D&ust=1491479807283000&usg=AFQjCNFT5Xzp_9_45YIQ-0GR0UuV8xLILQ"
                >{{$t("cookie.how.three")}}</a
              >
            </li>
            <li>
              <a
                href="https://www.google.com/url?q=http://windows.microsoft.com/es-es/windows-vista/block-or-allow-cookies&sa=D&ust=1491479807284000&usg=AFQjCNFT8rPLRd8ZdCrmxDNkLAv8RhYRTg"
                >{{$t("cookie.how.four")}}</a
              >
            </li>
            <li>
              <a
                href="https://www.google.com/url?q=http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9&sa=D&ust=1491479807285000&usg=AFQjCNF9oECm0dg_3XyL9IyTePwvZMc--w"
                >{{$t("cookie.how.five")}}</a
              >
            </li>
            <li>
              <a
                href="https://www.google.com/url?q=http://windows.microsoft.com/es-es/internet-explorer/delete-manage-cookies#ie=ie-10&sa=D&ust=1491479807286000&usg=AFQjCNG_be2prt0SGIbLHbLYlTvBKuXLog"
                >{{$t("cookie.how.six")}}</a
              >
            </li>
            <li>
              <a
                href="https://www.google.com/url?q=http://windows.microsoft.com/es-es/internet-explorer/delete-manage-cookies#ie=ie-11&sa=D&ust=1491479807287000&usg=AFQjCNH0Nga8-Cr9s6KJKl0PtnBZRBRprw"
                >{{$t("cookie.how.seven")}}</a
              >
            </li>
            <li>
              <a
                href="https://www.google.com/url?q=https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we&sa=D&ust=1491479807289000&usg=AFQjCNFXBrFz_ZqVfoUe64PmDCvaPiEzyA"
                >{{$t("cookie.how.eight")}}</a
              >
            </li>
            <li>
              <a
                href="https://www.google.com/url?q=http://help.opera.com/Linux/10.60/es-ES/cookies.html&sa=D&ust=1491479807290000&usg=AFQjCNHN2JtRlZ81BjxHnhTCH16dsgGeIQ"
                >{{$t("cookie.how.nine")}}</a
              >
            </li>
            <li>
              <a
                href="https://www.google.com/url?q=https://support.apple.com/kb/PH19214?viewlocale=es_ES&locale=es_ES&sa=D&ust=1491479807292000&usg=AFQjCNFrGYaCxRmF4fWF9sZdkf7q1TQECQ"
                >{{$t("cookie.how.ten")}}</a
              >
            </li>
            <li>
              <a
                href="https://www.google.com/url?q=https://support.apple.com/es-es/HT201265&sa=D&ust=1491479807293000&usg=AFQjCNF4ONSrS_BiUrCZXeJspPihfAhzvQ"
                >{{$t("cookie.how.eleven")}}</a
              >
            </li>
          </ul>
        </div>
        <div class="disc">
          <h4>{{$t("cookie.type.title")}}</h4>
          <p>{{$t("cookie.type.desc")}}</p>
        </div>
        <div class="disc">
          <h4>{{$t("cookie.what.title")}}</h4>
          <p>{{$t("cookie.what.desc")}}</p>
        </div>
        <div class="disc">
          <h4>{{$t("cookie.whatType.title")}}</h4>
          <p>{{$t("cookie.whatType.accordingTitle")}}</p>
          <ul v-html='$t("cookie.whatType.accordingOne")'></ul>
          <ul v-html='$t("cookie.whatType.accordingTwo")'></ul>
          <p>{{$t("cookie.whatType.dependingTitle")}}</p>
          <ul v-html='$t("cookie.whatType.dependingOne")'></ul>
          <ul v-html='$t("cookie.whatType.dependingTwo")'></ul>
          <p>{{$t("cookie.whatType.finallyTitle")}}</p>
          <ul v-html='$t("cookie.whatType.finallyOne")'></ul>
          <ul v-html='$t("cookie.whatType.finallyTwo")'> </ul>
          <ul v-html='$t("cookie.whatType.finallyThree")'></ul>
        </div>
        <div class="disc">
          <h4>{{$t("cookie.used.title")}}</h4>
          <table>
            <tr>
              <th>{{$t("cookie.used.name")}}</th>
              <th>{{$t("cookie.used.origin")}}</th>
              <th>{{$t("cookie.used.purpose")}}</th>
              <th>{{$t("cookie.used.type")}}</th>
              <th>{{$t("cookie.used.expiration")}}</th>
            </tr>
            <tr>
              <td>{{$t("cookie.used.nameOne")}}</td>
              <td>{{$t("cookie.used.originOne")}}</td>
              <td>{{$t("cookie.used.purposeOne")}}</td>
              <td>{{$t("cookie.used.typeOne")}}</td>
              <td>{{$t("cookie.used.expirationOne")}}</td>
            </tr>
            <tr>
              <td>{{$t("cookie.used.nameTwo")}}</td>
              <td>{{$t("cookie.used.originOne")}}</td>
              <td>{{$t("cookie.used.purposeOne")}}</td>
              <td>{{$t("cookie.used.typeOne")}}</td>
              <td>{{$t("cookie.used.expirationTwo")}}</td>
            </tr>
            <tr>
              <td>{{$t("cookie.used.nameThree")}}</td>
              <td>{{$t("cookie.used.originTwo")}}</td>
              <td>{{$t("cookie.used.purposeOne")}}</td>
              <td>{{$t("cookie.used.typeOne")}}</td>
              <td>{{$t("cookie.used.expirationThree")}}</td>
            </tr>
            <tr>
              <td>{{$t("cookie.used.namefour")}}</td>
              <td>{{$t("cookie.used.originThree")}}</td>
              <td>{{$t("cookie.used.purposeOne")}}</td>
              <td>{{$t("cookie.used.typeOne")}}</td>
              <td>{{$t("cookie.used.expirationFour")}}</td>
            </tr>
            <tr>
              <td>{{$t("cookie.used.nameFive")}}</td>
              <td>{{$t("cookie.used.originFour")}}</td>
              <td>{{$t("cookie.used.purposeOne")}}</td>
              <td>{{$t("cookie.used.typeOne")}}</td>
              <td>---</td>
            </tr>
            <tr>
              <td>{{$t("cookie.used.nameSix")}}</td>
              <td>{{$t("cookie.used.originOne")}}</td>
              <td>{{$t("cookie.used.purposeTwo")}}</td>
              <td>{{$t("cookie.used.typeTwo")}}</td>
              <td>{{$t("cookie.used.expirationSix")}}</td>
            </tr>
            <tr>
              <td>{{$t("cookie.used.nameSeven")}}</td>
              <td>{{$t("cookie.used.originOne")}}</td>
              <td>{{$t("cookie.used.purposeTwo")}}</td>
              <td>{{$t("cookie.used.typeTwo")}}</td>
              <td>{{$t("cookie.used.expirationSix")}}</td>
            </tr>
            <tr>
              <td>{{$t("cookie.used.nameEight")}}</td>
              <td>{{$t("cookie.used.originOne")}}</td>
              <td>{{$t("cookie.used.purposeTwo")}}</td>
              <td>{{$t("cookie.used.typeTwo")}}</td>
              <td>{{$t("cookie.used.expirationSix")}}</td>
            </tr>
          </table>
        </div>
        <div class="disc">
          <h4>{{$t("cookie.thirdParty.title")}}</h4>
          <p></p>
          <ul>
            <li>
              <a
                href="https://www.google.com/url?q=https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage&sa=D&ust=1491479807341000&usg=AFQjCNFiXQIc7T2M1_hu5nAy7N9DLABlAw"
                >{{$t("cookie.thirdParty.ga")}}</a>
              {{$t("cookie.thirdParty.gaDesc")}} <br />{{$t("cookie.thirdParty.gaDescTwo")}}
              <a
                href="https://www.google.com/url?q=https://tools.google.com/dlpage/gaoptout&sa=D&ust=1491479807342000&usg=AFQjCNFQNs6-yHSKuYY7hKTU9o_PRPAjhQ"
                >{{$t("cookie.thirdParty.here")}}</a>
            </li>
            <li>
              <a
                href="https://www.google.com/url?q=http://www.google.com/policies/privacy/&sa=D&ust=1491479807343000&usg=AFQjCNFcN1_bsSKP4ksix1e8kQU_PApI4Q"
                >{{$t("cookie.thirdParty.google")}}</a
              >
              {{$t("cookie.thirdParty.googleDesc")}}

              <br />
              {{$t("cookie.thirdParty.googleDescTowFirst")}}
              <a
                href="https://www.google.com/url?q=https://www.google.com/policies/technologies/types/&sa=D&ust=1491479807345000&usg=AFQjCNGWTlK7g3XNn3S0ctjVUEHyvJKjNQ"
                >{{$t("cookie.thirdParty.googleDescTowC")}}</a
              > {{$t("cookie.thirdParty.googleDescTowLast")}}
            </li>
            <li>
              <a
                href="https://www.google.com/url?q=http://www.google.com/policies/privacy/&sa=D&ust=1491479807346000&usg=AFQjCNFURx_uqYbFhKj887fQ6rJ7VsTFbQ"
                >{{$t("cookie.thirdParty.googleMaps")}}</a
              >
              {{$t("cookie.thirdParty.googleMapsDesc")}}
            </li>
            <li>
              <a href="https://stripe.com/es/legal">{{$t("cookie.thirdParty.stripe")}}</a>
              {{$t("cookie.thirdParty.stripeDesc")}}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cookie",
  created() {
    // this.amplitude.getInstance().logEvent('CookiePolicyScreen')
    this.eventLog("CookiePolicyScreen");
  },
};
</script>

<style lang="scss" scoped>
@import "../style/text";
.cookie-wrap {
  text-align: left;
}
</style>
