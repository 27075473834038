<template>
    <div class="ps-wrap">
        <div class="progress-wrap" v-if="pay_status === 0">
            <div class="content">
                <h1 class="title-tips">{{title_list[currentIndex]}}</h1>
                <div class="progress-box">
                    <div class="progress" :style="{backgroundPosition: num + 'px'}"></div>
                </div>
            </div>
        </div>

        <div class="pay-success" v-if="pay_status === 1">
            <i></i>
            <p>{{$t("success.titleTwo")}}</p>
        </div>
        <div class="pay-fail" v-if="pay_status === 2">
            <i></i>
            <p>{{$t("success.errTitle")}}</p>
            <a class="back-pay" @click="proceedPay()">{{$t("success.errBtn")}}</a>
        </div>

        <div v-show="shadow_status === 0" class="shadow-box stripe-card-shadow">
            <div class="pay-box">
                <img src="@/assets/images/index-pay-icon.png" alt="">
                <div class="close-btn" @click="closeBtn()" >
                    <img src="@/assets/images/pay-close.png" alt="">
                </div>
                <div class="top">
                    <h2>{{$t("map.locaOf")}} <span class="phoneNumber">{{userObj.phoneNumber}}</span></h2>
                    <p class="txt">{{$t("index.pay.TrialTime")}}</p>
                    <div class="line"></div>
                    <p class="email-txt">{{userObj.email}}</p>
                </div>

                <div class="form-box">
                    <div class="inp-box lnline-car">
                        <div class="car">
                            <img src="@/assets/images/pay-car-number-icon.png" alt="">
                            <p id="card-number"></p>
                        </div>
                        <div class="car-info">
                            <div>
                                <img src="@/assets/images/pay-date-icon.png" alt="">
                                <p id="card-expiry"></p>
                            </div>
                            <div>
                                <img src="@/assets/images/pay-cvc-icon.png" alt="">
                                <p id="card-cvc"></p>
                            </div>
                        </div>
                    </div>

                    <a class="pay-btn price-txt" id="stripe-card" @click="stripePay()">{{$t("index.pay.pay")}} $ <span>{{userObj.price.contentJsonObject.trialPrice}}</span></a>

                    <!-- <a class="pay-btn price-txt PayPal" @click="paypal()" data-paypal="0">
                        <img src="@/assets/images/Paypal.png" alt="">
                    </a> -->

                    <p class="err-tips" :style="{display: stripeErr_status? 'flex' :'none'}">
                        <img src="@/assets/images/pay-err-tips-icon.png" alt="">
                        <span>{{stripeErr_text}}</span>
                    </p>
                    <div class="pay-loader-box" :style="{display: payLoad? 'flex':'none'}">
                        <div class="loader"></div>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="shadow_status === 1" class="shadow-box stripe-card-shadow">
            <div class="pay-box">
                <img src="@/assets/images/index-pay-icon.png" alt="">
                <div class="close-btn" @click="closePop()" >
                    <img src="@/assets/images/pay-close.png" alt="">
                </div>
                <div class="top">
                    <h2>{{$t("map.locaOf")}} <span class="phoneNumber">{{userObj.phoneNumber}}</span></h2>
                    <p class="txt">{{$t("index.pay.TrialTime")}}</p>
                    <div class="line"></div>
                    <p class="email-txt">{{userObj.email}}</p>
                </div>

                <div class="form-box">
                    <div class="inp-box lnline-car">
                        <div class="car">
                            <img src="@/assets/images/pay-car-number-icon.png" alt="">
                            <p id="card-number"></p>
                        </div>
                        <div class="car-info">
                            <div>
                                <img src="@/assets/images/pay-date-icon.png" alt="">
                                <p id="card-expiry"></p>
                            </div>
                            <div>
                                <img src="@/assets/images/pay-cvc-icon.png" alt="">
                                <p id="card-cvc"></p>
                            </div>
                        </div>
                    </div>

                    <a class="pay-btn price-txt" id="stripe-card" @click="stripePay()">{{$t("index.pay.pay")}} $ <span>{{userObj.price.contentJsonObject.trialPrice}}</span></a>

                    <a class="pay-btn price-txt PayPal" data-paypal="0" @click="paypal">
                        <img src="@/assets/images/Paypal.png" alt="" @click="paypal">
                    </a>

                    <p class="err-tips" :style="{display: stripeErr_status? 'flex' :'none'}">
                        <img src="@/assets/images/pay-err-tips-icon.png" alt="">
                        <span>{{stripeErr_text}}</span>
                    </p>
                    <div class="pay-loader-box" :style="{display: payLoad? 'flex':'none'}">
                        <div class="loader"></div>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="shadow_status === 2" class="shadow-box MyLianlian-shadow" >
            <div class="pay-box">
                <img src="@/assets/images/index-pay-icon.png" alt="">
                <div class="close-btn" @click="closePop()">
                    <img src="@/assets/images/pay-close.png" alt="">
                </div>
                <div class="top">
                    <h2>Location of <span class="phoneNumber">{{userObj.phoneNumber}}</span></h2>
                    <p class="txt">2-Day trial - Without Permanence</p>
                    <div class="line"></div>
                    <p class="email-txt">{{userObj.email}}</p>
                </div>

                <div class="form-box">
                    <div class="list-txt">
                        <p>
                            <img src="@/assets/images/price-success-icon.png" alt="">
                            <span>All Regions</span>
                        </p>
                        <p>
                            <img src="@/assets/images/price-success-icon.png" alt="">
                            <span>Precise Geographic Location</span>
                        </p>
                        <p>
                            <img src="@/assets/images/price-success-icon.png" alt="">
                            <span>Any Mobile Phone Brand</span>
                        </p>
                        <p>
                            <img src="@/assets/images/price-success-icon.png" alt="">
                            <span>Any Mobile Telecommunications Company</span>
                        </p>
                    </div>
                    <!-- <a class="lianlian-pay price-txt">Pay $ <span>--</span></a> -->
                    <a class="lianlianBtn pay-btn" @click="openLianLianCrad">
                        <img src="@/assets/images/Stripe-checkout.png" alt="">
                        <p>Credit Card</p>
                    </a>
                    <a class="pay-btn price-txt PayPal" data-paypal="0" @click="paypal">
                        <img src="@/assets/images/Paypal.png" alt="">
                    </a>

                    <p class="err-tips" :style="{display: stripeErr_status? 'flex' :'none'}">
                        <img src="@/assets/images/pay-err-tips-icon.png" alt="">
                        <span>{{stripeErr_text}}</span>
                    </p>
                    <div class="pay-loader-box" :style="{display: payLoad? 'flex':'none'}">
                        <div class="loader"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="shadow-box MyLianlianCrad-shadow" v-show="MyLianlianCrad">
            <div class="pay-box">
                <img src="@/assets/images/index-pay-icon.png" alt="">
                <div class="close-btn">
                    <img src="@/assets/images/pay-close.png" alt="">
                </div>
                <div class="top">
                    <h2>Location of <span class="phoneNumber">{{userObj.phoneNumber}}</span></h2>
                    <p class="txt">2-Day trial - Without Permanence</p>
                    <div class="line"></div>
                    <p class="email-txt">{{userObj.email}}</p>
                </div>

                <div class="form-box">
                    <div id="lianlian-crad"></div>
                    <div class="my-lianlian-from">
                        <div class="lianlian-input-box" style="margin-top: 0;">
                            <p>Name on card</p>
                            <input class="lianlian-name" type="text" name="name" v-model="lianlinaPayUserInfo.name">
                        </div>
                        <div class="lianlian-input-box">
                            <p>Email</p>
                            <input disabled class="lianlian-email" type="text" name="email" v-model="userObj.email">
                        </div>
                        <div class="lianlian-input-box">
                            <p>Billing address</p>
                            <div class="ll-input-group">
                                <select name="country" id="territory" aria-placeholder="Country/Region"
                                        v-model="lianlinaPayUserInfo.territoryVal" @change="territoryChange(lianlinaPayUserInfo.territoryVal)">
                                    <option v-for="item in countryData.territory" :value="item.code" :key="item.name">{{item.name}}</option>
                                </select>
                                <input class="lianlian-addressA" type="text" name="address"
                                       placeholder="Address line 1" v-model="lianlinaPayUserInfo.addressA">
                                <input class="lianlian-addressB" type="text" name="address"
                                       placeholder="Address line 2" v-model="lianlinaPayUserInfo.addressB">
                                <div class="inputs">
                                    <input class="lianlian-city" type="text" name="city"
                                           placeholder="City" v-model="lianlinaPayUserInfo.city">
                                    <input class="lianlian-zip" type="text" name="zip"
                                           placeholder="ZIP" v-model="lianlinaPayUserInfo.zip">
                                </div>
                                <select name="state" id="state" v-model="lianlinaPayUserInfo.state"
                                        v-show="countryData.state[lianlinaPayUserInfo.territoryVal]['stateList']">
                                    <option v-for="item in countryData.state[lianlinaPayUserInfo.territoryVal]['stateList']"
                                            :value="item['$id']" :key="item['$elt']">{{item['$elt']}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <a class="myPay Mylianlian-pay price-txt" style="margin-left: auto;margin-right: auto;" @click="lianlianPay">
                        Pay $ <span>{{userObj.price.contentJsonObject.trialPrice}}</span>
                    </a>
                    <p class="err-tips">
                        <img src="@/assets/images/pay-err-tips-icon.png" alt="">
                        <span></span>
                    </p>
                    <div class="pay-loader-box" :style="{display: payLoad? 'flex':'none'}">
                        <div class="loader"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="paddle-wrap" :style="{display: paddleCheckout? 'flex':'none'}">
            <div class="checkout-container">
                <div class="close-btn" @click="paddleCheckoutClose">
                    <img src="@/assets/images/pay-close.png" alt="">
                </div>
                <div class="paddle-checkout"></div>
            </div>
        </div>

    </div>
</template>

<script>
    import {loadStripe} from '@stripe/stripe-js'
    import {mapMutations} from "vuex";
    import countryData from "@/assets/js/countryData";

    export default {
        name: "pay-success",
        data() {
            return{
                shadow_status: -1,
                pay_status: 0,
                interval2: null,
                interval: null,
                progressNum: 0,
                currentIndex: 0,
                title_list : [
                    this.$t("success.titleListOne"),
                    this.$t("success.titleListTwo"),
                    this.$t("success.titleListThree"),
                    this.$t("success.titleListFour"),
                ],
                num: 0,
                payssion_time: 0,
                checkRegister_flag: true,
                userObj: JSON.parse(sessionStorage.getItem('order_userObj')),
                elementStyles:{
                    base: {
                        color: '#606266',
                        '::placeholder': {
                            color: '#C0C4CC',
                        },
                    },
                },
                stripeErr_text: '',
                stripeErr_status: false,
                stripe: null,
                cardNumber: null,
                logEventSubscribe: 'Stripe',
                payLoad: false,
                paddleCheckout: false,
                MyLianlianCrad: false,
                countryData,

                lianlianElements: null,
                lianlinaPayUserInfo: {
                    territoryVal: 'AL',
                    name: '',
                    addressA: '',
                    addressB: '',
                    city: '',
                    zip: '',
                    state: ''
                },
                llpInterval: null

            }
        },
        created() {
            this.eventLog('paySuccess_page')
        },
        async mounted() {

            this.llpInterval = setInterval(() =>{
                if (window.LLP) {
                    this.lianlianElements = window.LLP.elements();
                    console.log('d-llp')
                    clearInterval(this.llpInterval)
                }
            })

            document.querySelector('.navbar').style.display = 'block'
            document.querySelector('.footer-box').style.display = 'none'

            if (this.$getQueryString('successPay') !== 'stripeCheckoutOld') {
                this.loading_progress()
            }
            if (this.$getQueryString('successPay') === 'stripeCheckout') {
                this.logEventSubscribe = 'Stripe'
            }
            else if (this.$getQueryString('successPay') === 'PayPal_Stripe_Checkout'){
                this.logEventSubscribe = 'Stripe'
            }
            else if (this.$getQueryString('successPay') === 'PayPalCheckout') {
                this.logEventSubscribe = 'Paypal'
            }
            else if (this.$getQueryString('successPay') === 'payssion_Checkout') {
                this.logEventSubscribe = 'Payssion'
            }
            else if (this.$getQueryString('successPay') === 'paddle_Checkout') {
                this.logEventSubscribe = 'Paddle'
            }
            else if (this.$getQueryString('successPay') === 'stripeCheckoutOld') {
                this.logEventSubscribe = 'Stripe'
                this.pay_status = 2
                this.getLocation_code(this.userObj.phoneNumber)
            }
            else if (this.$getQueryString('successPay') === 'Stripe_Card') {

                this.logEventSubscribe = 'Stripe'
                this.stripe = await loadStripe(this.userObj.price.publicStripeKey)
                let elementInput = this.stripe.elements();
                this.cardNumber = elementInput.create('cardNumber', {
                    style: this.elementStyles
                });
                this.cardNumber.mount('#card-number');

                let cardExpiry = elementInput.create('cardExpiry', {
                    style: this.elementStyles
                });
                cardExpiry.mount('#card-expiry');

                let cardCvc = elementInput.create('cardCvc', {
                    style: this.elementStyles
                });
                cardCvc.mount('#card-cvc');
                this.cardNumber.on('change',(event)=>{
                    this.stripeErr(event)
                })
                cardExpiry.on('change',(event)=>{
                    this.stripeErr(event)
                })
                cardCvc.on('change',(event)=>{
                    this.stripeErr(event)
                })
            }
            else if (this.$getQueryString('successPay') === 'LianLianCheckout') {
                this.logEventSubscribe = 'Lianlian'
            }
        },

        destroyed() {
            // this.checkRegister('',false)
        },
        methods:{
            paypal() {
                this.payLoad = true
                let params = JSON.parse(localStorage.getItem('params'))
                params.payWay = 0
                params.successUrl = this.add_url_search([location.origin , '/pay-success.html'], 'successPay=PayPalCheckout');
                params.canelUrl = this.add_url_search([location.origin , location.pathname], 'canelPay=PayPalCheckout')
                // this.amplitude.getInstance().logEvent('InitiateCheckout')
                this.eventLog('InitiateCheckout')
                this.$axios.post('/portal/order',params).then(res=>{
                    if (res.success == 1) {
                        sessionStorage.setItem('order_code', res.code)
                        sessionStorage.setItem('order_userObj', JSON.stringify(this.userObj))
                        location.href = res.obj
                    } else {
                        this.payLoad = false
                        this.toast(res.msg)
                    }
                }).catch(err=>{
                    console.log(err)
                    this.payLoad = false
                })

            },
            async stripePay() {
                try {

                    this.payLoad = true
                    this.stripeErr_text = ''
                    this.stripeErr_status = false
                    // this.amplitude.getInstance().logEvent('InitiateCheckout')
                    this.eventLog('InitiateCheckout')
                    let params = JSON.parse(localStorage.getItem('params'))
                    const stripeRes = await this.stripe.createPaymentMethod({
                        type: 'card',
                        card: this.cardNumber,
                        "billing_details": {
                            "email": params.email,
                        },
                    })
                    if (stripeRes.error) {
                        this.stripeErr(stripeRes)
                        this.payLoad = false
                        return
                    }
                    params.toPayId = stripeRes.paymentMethod.id
                    const orderRes = await this.$axios.post('/portal/order',params)
                    if (orderRes.success != '1') {
                        this.stripeErr_text = orderRes.msg
                        this.stripeErr_status = true
                        this.payLoad = false
                    }else {
                        this.payLoad = false
                        this.shadow_status = -1
                        this.num = 0
                        this.currentIndex = 0
                        this.pay_status = 0
                        clearInterval(this.interval2)
                        this.loading_progress()
                        // var obj = JSON.parse(res.obj)
                        sessionStorage.setItem('order_code', orderRes.code)
                        sessionStorage.setItem('order_userObj', JSON.stringify(this.userObj))
                    }
                }catch (e) {
                    console.log(e)
                    this.payLoad = false
                }
            },

            paddlePay() {
                let userObj = JSON.parse(sessionStorage.getItem('order_userObj'))
                let params = JSON.parse(localStorage.getItem('params'))
                this.loading()
                let that = this
                this.$axios.post('/portal/order',params).then(res=>{
                    if (res.success == 1) {
                        sessionStorage.setItem('order_code', res.code)
                        sessionStorage.setItem('order_userObj', JSON.stringify(this.userObj))
                        window.Paddle.Setup({
                            vendor: 131547, // Replace with your Vendor ID.
                            eventCallback: function(eventData) {
                                console.log(eventData)
                            }
                        });
                        window.Paddle.Checkout.open({
                            email: userObj.email,
                            postcode: params.zip,
                            country: params.country,
                            success: this.add_url_search([location.origin , '/pay-success'], 'successPay=paddle_Checkout'),
                            override: res.obj,
                            method: 'inline',
                            allowQuantity: false,
                            disableLogout: true,
                            frameTarget: 'paddle-checkout', // The className of your checkout <div>
                            frameInitialHeight: 416,
                            frameStyle: 'width:100%; min-width:100%; background-color: transparent; border: none;',    // Please ensure the minimum width is kept at or above 495px
                            loadCallback: function () {
                                that.hideLoading()
                                that.paddleCheckout = true
                                that.shadow_status = -1
                            }
                        });
                    } else {
                        this.payLoad = false
                        this.toast(res.msg)
                    }
                }).catch(err=>{
                    console.log(err)
                    this.toast(err.msg || this.$t("tips.paymentErr"))
                    this.payLoad = false
                })

                // postFetch(params, successPayCallbackPaddleCheckout, function (err) {
                //     $('.pay-loader-box').css('display', 'none')
                //     toggleTips(true, err.msg, 4000)
                // })
            },

            checkStripeKey() {
                this.stripeErr_text = ''
                this.stripeErr_status = false

                this.$axios.get('/other/checkStripe/'+this.userObj.price.contentJsonObject.stripeCode).then(res=>{
                    if (res.success == 1) {
                        this.initStripe(res.obj.publicStripeKey)
                        this.userObj.price.contentJsonObject.stripeCode = res.obj.stripeCode
                        this.payLoad = false
                    }
                }).catch(err=>{
                    console.log(err)
                    this.payLoad = false
                })
            },
            async initStripe(key) {
                this.stripe = await loadStripe(key)
                this.$nextTick(() => {
                    let elementInput = this.stripe.elements();
                    this.cardNumber = elementInput.create('cardNumber', {
                        style: this.elementStyles
                    });
                    this.cardNumber.mount('#card-number');

                    let cardExpiry = elementInput.create('cardExpiry', {
                        style: this.elementStyles
                    });
                    cardExpiry.mount('#card-expiry');

                    let cardCvc = elementInput.create('cardCvc', {
                        style: this.elementStyles
                    });
                    cardCvc.mount('#card-cvc');

                    this.cardNumber.on('change', (event)=> {
                        this.stripeErr(event)
                    })
                    cardExpiry.on('change', (event)=> {
                        this.stripeErr(event)
                    })
                    cardCvc.on('change', (event)=> {
                        this.stripeErr(event)
                    })
                    this.hideLoading()
                })
            },
            paddleCheckoutClose() {
                this.paddleCheckout = false
                document.querySelector('.paddle-checkout iframe').remove()
            },
            openLianLianCrad() {
                // payLoad
                // this.amplitude.getInstance().logEvent('InitiateCheckout')
                this.eventLog('InitiateCheckout')
                let params = {
                    "currency": "USD",
                    "email": this.userObj.email,
                    "money": this.userObj.price.presentPrice,
                    "priceSetupId": this.userObj.price.id,
                    "renew": true,
                    "subscribeMoney": this.userObj.price.presentPrice,
                    "type": this.userObj.price.orderType,
                    "userEmail": this.userObj.email,
                    "successUrl": process.env.VUE_APP_BASE_API + '/otherHtml/paySuccessUrl?resultUrl=' + encodeURIComponent(location.origin + '/pay-success'),
                    "canelUrl": this.add_url_search([location.origin , location.pathname], 'canelPay=LianLianCheckout'),
                    "urlParams": localStorage.getItem('url_search') ? localStorage.getItem('url_search').substr(1) + '&successPay=LianLianCheckout' :'successPay=LianLianCheckout',
                    "url": '/v1/portal/order',
                    isTryout: this.userObj.price.contentJsonObject.isTryout,
                    apkChannel: window.channel,
                    webId: window.uuidstr,
                    payWay: 8,
                    paymentType: 'iframe'
                }
                params.payAccountCode = this.userObj.price.contentJsonObject.stripeCode
                this.payLoad = true
                params.content = JSON.stringify({
                    phoneNumber: this.userObj.phoneNumber
                })
                localStorage.setItem('params',JSON.stringify(params))
                this.$axios.post('/portal/order',params).then(res=>{
                    if (res.success == 1) {
                        this.MyLianlianCrad = true
                        this.shadow_status = -1
                        let card = this.lianlianElements.create('card', {
                            key: JSON.parse(res.obj.content).lianlianToken,
                            style: {
                                base: {
                                    fontFamily: 'San Francisco, Roboto, Helvetica Neue, Helvetica, Arial',
                                    backgroundColor: '#FFFFFF',
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    floatLabelSize: '14px',
                                    color: '#bcbcbc',
                                    height: '264px'
                                },
                            }
                        });
                        card.mount('#lianlian-crad');
                        this.payLoad = false
                        this.shadow_status = -1
                        this.MyLianlianCrad = true
                    }else {
                        this.payLoad = false
                        this.toast(res.msg)
                    }
                }).catch(err=>{
                    this.toast(err.msg || this.$t("tips.paymentErr"))
                    this.payLoad = false
                })
            },
            lianlianPay() {
                this.loading()
                if (this.lianlinaPayUserInfo.name == '') {
                    this.toast(this.$t("tips.cradNameNoEmpty"))
                    return
                }
                if (this.lianlinaPayUserInfo.addressA == '') {
                    this.toast(this.$t("tips.addressNoEmpty"))
                    return
                }
                if (this.lianlinaPayUserInfo.city == '') {
                    this.toast(this.$t("tips.cityNoEmpty"))
                    return
                }
                if (this.lianlinaPayUserInfo.zip == '') {
                    this.toast(this.$t("tips.zipNoEmpty"))
                    return
                }

                if (this.countryData['post'][this.lianlinaPayUserInfo.territoryVal]) {
                    let reg = new RegExp(countryData['post']['US']['regex'])
                    if (!reg.test(this.lianlinaPayUserInfo.zip)) {
                        this.toast(this.$t("tips.zipIncorrect"))
                        return
                    }
                }
                let that = this
                window.LLP.confirmPay({
                    payment_method: {
                        cardName: this.lianlinaPayUserInfo.name,
                        email: this.userObj.email,
                        billing_details: {
                            cardCountry: this.lianlinaPayUserInfo.territoryVal,
                            cardCity: this.lianlinaPayUserInfo.city,
                            cardLine1: this.lianlinaPayUserInfo.addressA,
                            cardLine2: this.lianlinaPayUserInfo.addressB,
                            cardState: this.lianlinaPayUserInfo.state,
                            cardPostcode: this.lianlinaPayUserInfo.zip,
                        },
                    },
                }).then(function (result) {
                    that.hideLoading()
                    if (result.code == 200000) {
                        that.$router.push({
                            path: '/pay-success',
                            query: that.routerQuery('successPay=LianLianCheckout')
                        })
                    }
                    if (result.error) {
                        that.toast(result.error.message)
                    } else {
                        that.toast(result.message)
                    }
                });
            },

            territoryChange(val) {
                this.lianlinaPayUserInfo.state = this.countryData.state[val]['stateList'] ? this.countryData.state[val]['stateList'][0]['$id'] : ''
            },
            proceedPay() {
                this.paddlePay()
                // this.shadow_status = 0
                // this.amplitude.getInstance().logEvent('CheckoutScreen',{
                //     Source: this.logEventSubscribe
                // })
                this.eventLog('CheckoutScreen-'+this.logEventSubscribe)
            },
            closeBtn(){
                this.shadow_status = -1
                // this.amplitude.getInstance().logEvent('ExitCheckout',{
                //     Source: this.logEventSubscribe
                // })
                this.eventLog('ExitCheckout-'+this.logEventSubscribe)
            },
            loading_progress() {
                this.interval = setInterval(()=>{
                    this.num += 10
                }, 100)
                this.interval2 = setInterval(()=>{
                    this.currentIndex += 1;
                    if (this.currentIndex === 3) {
                        clearInterval(this.interval2)
                        this.checkRegister(sessionStorage.getItem('order_code'))
                    }
                }, 1000)
            },
            checkRegister(code,status = true) {
                if (!status) {
                    return
                }
                this.$axios.get(`/portal/user/checkRegister/${code}`).then(res => {
                    if(this.$getQueryString('successPay') === 'payssion_Checkout' && this.checkRegister_flag) {
                        this.payssion_time = +new Date() + (30 * 1000);
                        this.checkRegister_flag = false
                    }
                    if (this.$getQueryString('successPay') === 'payssion_Checkout' && +new Date() > this.payssion_time) {
                        // this.amplitude.getInstance().logEvent('CheckoutResultScreen',{
                        //     Type: 'Failure',
                        //     Source: this.logEventSubscribe
                        // })
                        this.eventLog('CheckoutResultScreen-Failure'+this.logEventSubscribe)
                        this._pay_err()
                        return
                    }
                    if (res.success != '1') {
                        // this.amplitude.getInstance().logEvent('CheckoutResultScreen',{
                        //     Type: 'Failure',
                        //     Source: this.logEventSubscribe
                        // })
                        this.eventLog('CheckoutResultScreen-Failure'+this.logEventSubscribe)
                        this.pay_status = 2
                        this._pay_err()
                        return
                    }
                    if (!res.obj) {
                        this.checkRegister(code)
                    }else {
                        // this.amplitude.getInstance().logEvent('CheckoutResultScreen',{
                        //     Type: 'Success',
                        //     Source: this.logEventSubscribe
                        // })
                        this.eventLog('CheckoutResultScreen-Success'+this.logEventSubscribe)
                        // this.amplitude.getInstance().logEvent('Subscribe',{
                        //     Source: this.logEventSubscribe
                        // })
                        this.eventLog('Subscribe-'+this.logEventSubscribe)
                        // log('Subscribe')
                        this.gtag('event', 'YEMIAN_FMP', {
                            'event_category': 'JINRU',
                            'event_label': 'CHENGGONG'
                        });
                        window.fbq('track', 'StartTrial');
                        localStorage.setItem('phoneNumber', this.userObj.phoneNumber)
                        this.setUserInfo(res.obj)
                        // localStorage.setItem('userInfo', JSON.stringify(res.obj))
                        // sessionStorage.removeItem('order_code', res.code)
                        this.getLocation_code(this.userObj.phoneNumber)
                    }
                    console.log(res)
                }).catch(err=>{
                    console.log(err)
                    this.toast(err.msg,4000)
                })
            },
            getLocation_code(phoneNumber) {
                let params = {
                    parentPhone: 'null',
                    childPhone: phoneNumber
                }
                this.$axios.post('/spyContent/phoneLocation',params).then(res=> {
                    if (res.success != '1') {
                        this.toast(res.msg)
                        return
                    }
                    this.pay_status = 1
                    this.eventLog('pay_success')
                    setTimeout(()=>{
                        this.$router.push({
                            path: '/map',
                            query: this.routerQuery('logEventSource=HomeScreen')
                        })
                    },1000)
                    localStorage.setItem('phoneNumber', phoneNumber)
                    sessionStorage.setItem('location_phone_id', res.obj.id)
                }).catch(err=> {
                    this.toast(err.msg)
                })

            },
            _pay_err() {
                this.num = 0
                this.pay_status = 2
                clearInterval(this.interval)
                localStorage.removeItem('userInfo')
            },
            stripeErr(event) {
                if (event.error) {
                    this.stripeErr_text = event.error.message
                    this.stripeErr_status = true
                } else {
                    this.stripeErr_text = ''
                    this.stripeErr_status = false
                }
            },
            ...mapMutations(['setUserInfo'])
        }
    }
</script>

<style lang="scss" scoped>
    @import "../style/index";
    @keyframes blink{
        0%{
            opacity: 1;
        }
        50%{
            opacity: .8;

        }
        100%{
            opacity: 1;
        }
    }
    .footer-box{
        display: none;
    }
    body{
        background: #FFFFFF;
    }
    @media (min-width:960px) {
        .progress-wrap{
            width: 100%;
            margin-top: 300px;
        }

        .progress-wrap .title-tips{
            width: 100%;
            text-align: center;
            font-size: 40px;
            font-weight: 600;
            color: #0CCFA9;
            line-height: 47px;
        }
        .progress-wrap .progress-box{
            width: 800px;
            height: 40px;
            border-radius: 20px;
            border: 3px solid #7AD6C4;
            margin: 40px auto;
            box-sizing: border-box;
            background: rgba(120, 220, 200, 0.06);
            box-shadow: inset 0px 8px 3px 0px rgba(0, 0, 0, 0.06);
            overflow: hidden;
        }

        .progress-wrap .progress-box .progress{
            width: 100%;
            height: 100%;
            background-image: url('~@/assets/images/pc/progress-item.png');
            background-size: 800px 38px;
            animation: blink 1.2s ease-in-out infinite;
            border-radius: 17px;
            transition: all .6s;
            background-color: #0CCFA9;
            background-size: 56px 38px;
        }
        .pay-success{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 238px;
        }
        .pay-success i{
            width: 458px;
            height: 300px;
            background-image: url('~@/assets/images/pc/pay-success.png');
            background-size: 458px;
            height: 300px;
        }
        .pay-success p{
            font-size: 40px;
            font-weight: 600;
            color: #0CCFA9;
            line-height: 47px;
            margin-top: 52px;
        }
        .pay-fail{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 195px;
        }
        .pay-fail i{
            width: 458px;
            height: 300px;
            background-image: url('~@/assets/images/pc/pay-fail.png');
            background-size: 458px;
            height: 300px;
        }
        .pay-fail p{
            font-size: 40px;
            font-weight: 600;
            color: #E73C44;
            line-height: 47px;
            margin-top: 52px;
        }
        .pay-fail a{
            width: 248px;
            height: 60px;
            background: #E73C44;
            border-radius: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            font-weight: 500;
            color: #FFFFFF;
            margin-top: 40px;
            cursor: pointer;
        }
        .pay-fail a:hover{
            background: #C13239;
        }
    }

    @media (max-width: 960px) {
        .progress-wrap{
            display: block;
            width: 100%;
            margin-top: 3.8rem;
        }

        .progress-wrap .title-tips{
            width: 100%;
            text-align: center;
            font-size: .48rem;
            font-weight: 500;
            color: #0CCFA9;
            line-height: .72rem;
        }
        .progress-wrap .progress-box{
            width: 6rem;
            height: .6rem;
            border-radius: .3rem;
            border: .04rem solid #7AD6C4;
            margin: .28rem auto;
            box-sizing: border-box;
            background: rgba(120, 220, 200, 0.06);
            box-shadow: inset 0px .08rem 3px 0px rgba(0, 0, 0, 0.06);
            overflow: hidden;
        }
        .progress-wrap .progress-box .progress{
            width: 100%;
            height: 100%;
            background-image: url('~@/assets/images/progress-item.png');
            background-size: .88rem .56rem;
            animation: blink 1.2s ease-in-out infinite;
            border-radius: .3rem;
            transition: all .6s;
            background-color: #0CCFA9;
        }
        .pay-success{
            width: 6.4rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            margin-top: 2.5rem;
        }
        .pay-success i{
            width: 4.58rem;
            height: 3rem;
            background-image: url('~@/assets/images/pay-success.png');
            background-size: 4.58rem;
            height: 3rem;
        }
        .pay-success p{
            font-size: .48rem;
            font-weight: 600;
            color: #0CCFA9;
            line-height: .72rem;
            text-align: center;
            margin-top: .4rem;
        }
        .pay-fail{
            width: 6.4rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            margin-top: 2.3rem;
        }
        .pay-fail i{
            width: 4.58rem;
            height: 3rem;
            background-image: url('~@/assets/images/pay-fail.png');
            background-size: 4.58rem;
            height: 3rem;
        }
        .pay-fail p{
            font-size: .48rem;
            font-weight: 600;
            color: #E73C44;
            line-height: .72rem;
            text-align: center;
            margin-top: .4rem;
        }
        .pay-fail a{
            width: 4.8rem;
            height: .88rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #E73C44;
            font-size: .32rem;
            font-weight: 600;
            color: #FFFFFF;
            border-radius: .44rem;
            margin-top: .48rem;
        }
        .pay-fail a:active{
            background: #C13239;
        }
    }
</style>
