<template>
    <div class="fp-wrap">
        <div class="login-box email-box" v-if="status ===0">
            <div class="banner-content"></div>
            <div class="top">
                <img src="@/assets/images/login-top-img.png" alt="">
                <h1>{{$t("login.forgetPassword")}}</h1>
            </div>
            <div class="form-box">
                <div class="input-box">
                    <p>{{$t("login.e_amil")}}</p>
                    <input type="text" class="email" v-model="email" @keydown="emailKeydown($event)">
                </div>
                <a class="recover-pass enter" @click="sendCode">{{$t("login.sendCodeUP")}}</a>
            </div>
        </div>

        <div class="login-box repeat"  v-if="status ===1">
            <div class="banner-content"></div>
            <div class="top">
                <img src="@/assets/images/login-top-img.png" alt="">
                <h1>{{$t("login.forgetPassword")}}</h1>
            </div>
            <div class="form-box">
                <div class="input-box">
                    <p>{{$t("login.codeUP")}}</p>
                    <input type="text" class="code" v-model="code" @keydown="codeKeydown($event)">
                </div>
                <div class="input-box">
                    <p>{{$t("login.passwordUP")}}</p>
                    <input type="password" class="password" v-model="password" @keydown="passwordKeydown($event)" ref="password">
                </div>
                <a class="enter repeat-btn" @click="repeat">{{$t("login.submitUP")}}</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "forget-password",
        data() {
            return{
                status: 0,
                email: '',
                code: '',
                password: ''
            }
        },
        created() {
            // this.amplitude.getInstance().logEvent('ForgetPasswordScreen')
            this.eventLog('ForgetPasswordScreen')
        },
        methods:{
            codeKeydown(e){
                if (e.key == 'Enter') {
                    this.$refs.password.focus()
                }
            },
            passwordKeydown(e) {
                if (e.key == 'Enter') {
                    this.repeat()
                }
            },
            repeat() {
                let code = this.code.trim()
                let password = this.password.trim()
                if (this.email === '' || password === '') {
                    this.toast(this.$t("tips.inputNoEmpty"))
                    return
                }
                if (code.length < 5) {
                    this.toast(this.$t("tips.enterCorrectCode"))
                    return
                }
                if (password.length < 6) {
                    this.toast(this.$t("tips.passWordNoSix"))
                    return
                }

                this.loading()
                this.$axios.put(`/admin/userSession/forgetPassword/${this.email}`,{
                    password: password,
                    email: this.email,
                    code: code
                }).then(res=>{
                    this.hideLoading()
                    if (res.success != '1') {
                        this.toast(res.msg)
                        return
                    }
                    this.toast(res.msg)
                    setTimeout(()=>{
                        this.$router.push({
                            path: '/login'
                        })
                    },2000)
                }).catch(err=>{
                    this.hideLoading()
                    this.toast(err.msg)
                })
            },

            emailKeydown(e) {
                if (e.key == 'Enter') {
                    this.sendCode()
                }
            },
            sendCode(){
                this.email = this.email.trim()
                if (this.email === '') {
                    this.toast(this.$t("tips.emailNoEmpty"))
                    return
                }
                if (!this.isEmailAvailable(this.email)) {
                    this.toast(this.$t("tips.emailIncorrect"))
                    return
                }
                this.loading()
                this.$axios.get(`/admin/userSession/forgetPassword/${this.email}`).then(res=>{
                    this.hideLoading()
                    if (res.success != '1'){
                        this.toast(res.msg)
                        return
                    }
                    this.status = 1
                    this.toast(this.$t("tips.codeSendEmail"))
                }).catch(err=>{
                    this.toast(err.msg)
                    this.hideLoading()
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../style/login";
</style>
